/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { Icon } from "@chakra-ui/react";
import { FaVolumeUp } from "react-icons/fa";

type Props = {
  audioUrl?: string;
  className?: string;
};

export default function AudioButton({ audioUrl }: Props) {
  const refAudio = React.useRef<HTMLAudioElement>(null);
  const playAudio = React.useCallback(() => {
    refAudio?.current?.play();
  }, []);

  if (!audioUrl) return null;

  return (
    <Box>
      <audio ref={refAudio} src={audioUrl} />
      <Button
        variant="setup"
        px="24px"
        fontSize="md"
        fontWeight="500"
        mb={"20px"}
        _hover={{ bg: "#ebedf0" }}
        _active={{
          bg: "#dddfe2",
          transform: "scale(0.98)",
          borderColor: "#bec3c9",
        }}
        onClick={() => {
          playAudio();
        }}
      >
        <Icon as={FaVolumeUp} />
        &nbsp;&nbsp;Click to listen
      </Button>
    </Box>
  );
}
