import React, { useEffect, useState } from 'react';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  useToast,
} from '@chakra-ui/react';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { amesSendMail } from 'helper/QueryHelper';
import { useHistory } from 'react-router-dom';
import { MODULE_ID } from 'constants/settings';

export default function UserForgotPassword() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const [resetPassword, setResetPassword] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const history = useHistory();
  const toast = useToast();

  // yup
  const Schema = yup.object().shape({
    email: yup
      .string()
      .required('Vui lòng nhập email')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Vui lòng nhập đúng định dạng email'
      ),
  });
  // hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });
  // Toast
  const changePasswordSuccess = () => {
    toast({
      title: 'Đổi mật khẩu thành công !',
      description: 'Mật khẩu mới đã được gửi đến email của bạn',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top',
      containerStyle: {
        mt: '40px',
      },
    });
  };

  // send form

  const onsubmit = async (values: any) => {
    if (values) {
      // const Email = values.email.toLowerCase();
      // const response = await query('p_System_User_ResetPassword', {
      //   Email,
      // });

      // if (response[0]?.userId > 0) {
      //   setResetPassword(response[0]?.userId);
      //   changePasswordSuccess();
      //   setDisableButton(true);
      //   return;
      // } else {
      //   toast({
      //     title: response[0]?.message,
      //     description: '',
      //     status: 'warning',
      //     duration: 3000,
      //     isClosable: true,
      //     position: 'top',
      //     containerStyle: {
      //       mt: '40px',
      //     },
      //   });
      //   setDisableButton(true);
      // }

      //======= test api Vĩ================
      const dataSendMail = {
        To: values.email.toLowerCase(),
        IsSendFast: true,
        moduleId: MODULE_ID,
        Cc: '',
        Bcc: '',
      };
      const response = await amesSendMail(
        'SendMailFastForgotPassOLPKids',
        dataSendMail
      );
      if (response.UserId > 0) {
        setResetPassword(response.UserId);
        changePasswordSuccess();
        setDisableButton(true);
        return;
      } else {
        toast({
          title: 'Email này chưa đăng ký !',
          description: '',
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'top',
          containerStyle: {
            mt: '40px',
          },
        });
        setDisableButton(true);
      }
      //=======End test api Vĩ================
    }
  };

  // sau 3s chuyển về trang login
  useEffect(() => {
    // Thông báo lỗi. Ẩn button 3s

    if (disableButton) {
      const timer = setTimeout(() => {
        setDisableButton(false);
      }, 3000);
      return () => clearTimeout(timer);
    }

    if (resetPassword > 0) {
      const timer = setTimeout(() => {
        history.push('/login');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [resetPassword, disableButton]);

  return (
    <>
      <CenteredAuth
        image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
        cardTop={{ base: '140px', md: '24vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
      >
        <Flex
          w="100%"
          maxW="max-content"
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          px={{ base: '25px', md: '0px' }}
          flexDirection="column"
        >
          <Box me="auto" mb="34px">
            <Heading
              color={textColor}
              fontSize={{ base: '3xl', md: '36px' }}
              mb="16px"
            >
              Quên mật khẩu?
            </Heading>
            <Text
              color={textColorSecondary}
              fontSize="md"
              w={{ base: '100%', lg: '456px' }}
              maxW="100%"
            >
              Nhập email của bạn để nhận mật khẩu mới
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', lg: '456px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
            align="start"
          >
            <FormControl>
              <form onSubmit={handleSubmit(onsubmit)}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  variant="auth"
                  fontSize="sm"
                  type="email"
                  placeholder="Nhập email"
                  mb="14px"
                  size="lg"
                  {...register('email')}
                />
                <Text fontSize={'xs'} mb="24px" color={'red'} ml="5px">
                  {errors?.email?.message}
                </Text>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  isDisabled={disableButton}
                  _hover={{ bg: brandStars }}
                >
                  Gửi
                </Button>
              </form>
            </FormControl>
          </Flex>
        </Flex>
      </CenteredAuth>
    </>
  );
}
