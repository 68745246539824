import { Icon } from '@chakra-ui/react';
import {
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Admin Imports

import QuestionControllerDashboard from './views/admin/dashboards/questionController/index';
import IndexDivideIntoGroups from 'views/admin/dashboards/divideIntoGroups';
import IndexInformationGroup from 'views/admin/dashboards/groups';
import IndexAdminProfile from 'views/admin/profile/IndexAdminProfile';

const routes = [
  // --- Dashboards ---
  {
    name: 'Bảng điều khiển',
    path: '/dashboards',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: '',
        layout: '/admin',
        path: '/dashboard/groups/:groupId/question-controller',
        component: QuestionControllerDashboard,
      },
      {
        name: 'Phân bổ nhóm',
        layout: '/admin',
        path: '/dashboard/divideIntoGroups',
        component: IndexDivideIntoGroups,
      },
      {
        name: 'Danh sách nhóm',
        layout: '/admin',
        path: '/dashboard/groups',
        component: IndexInformationGroup,
      },
      {
        name: 'Quản lý tài khoản',
        layout: '/admin',
        path: '/profile-setting',
        component: IndexAdminProfile,
      },
    ],
  },
];

export default routes;
