import { Box } from '@chakra-ui/react'
import React from 'react'
import QuestionController from './components/QuestionController/index'

const QuestionControllerDashboard = () => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <QuestionController />
    </Box>
  )
}

export default QuestionControllerDashboard
