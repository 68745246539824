import IndexProfile from 'views/user/profile/IndexProfile';
import IndexWaitingGroup from 'views/user/waitingGroup';
import IndexWaitingRoom from 'views/user/waitingRoom';
import OlympicViewer from './views/user/OlympicViewer';
import UserLogin from 'components/form/user/UserLogin';
export default [
  {
    name: 'Waiting Room',
    layout: '/user',
    path: '/room',
    exact: false,
    component: IndexWaitingRoom,
  },
  {
    name: 'Olympic Viewer',
    layout: '/user',
    path: '/group/:groupId/question/:questionId',
    exact: false,
    component: OlympicViewer,
  },
  {
    name: 'Waiting Group',
    layout: '/user',
    path: '/group/:groupId',
    exact: false,
    component: IndexWaitingGroup,
  },
  {
    name: 'Quản lý tài khoản',
    layout: '/user',
    path: '/profile-setting',
    exact: false,
    component: IndexProfile,
  },
  {
    //  redirect to login
    name: 'login',
    layout: '/user',
    path: '/',
    exact: false,
    component: UserLogin,
  },
];
