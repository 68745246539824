import { Box } from '@chakra-ui/react';
import UserRegister from 'components/form/user/UserRegister';
import React from 'react';

export default function Register() {
  return (
    <Box>
      <UserRegister />
    </Box>
  );
}
