// Chakra imports
import { Flex, Button, Text, useColorModeValue, Grid } from "@chakra-ui/react";

export default function QuestionItem(props: {
  index: number;
  value: string;
  name: string;
  actionStart?: (i: number) => void;
  actionEnd?: (i: number) => void;
  actionHelp?: (i: number) => void;
  [x: string]: any;
}) {
  const { index, value, name, actionStart, actionEnd, actionHelp, ...rest } =
    props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Flex
      justifyContent="space-between"
      flexDirection={{ base: "column", md: "row" }}
      alignItems="center"
      w="100%"
      {...rest}
    >
      <Text
        color={textColor}
        fontSize="md"
        me="6px"
        fontWeight="700"
        mb={{ base: "10px", md: "0px" }}
      >
        {name}
      </Text>
      <Flex
        align="center"
        flexDirection={{ base: "column", md: "row" }}
        mb={{ base: "20px", md: "0px" }}
      >
        <Text
          ms="auto"
          mb={{ base: "10px", md: "0px" }}
          color="secondaryGray.600"
          fontSize="md"
          me={{ base: "10px", md: "30px" }}
          fontWeight="500"
        >
          {value}
        </Text>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <Button
            variant="setup"
            px="24px"
            onClick={() => {
              actionStart(index);
            }}
            fontSize="md"
            fontWeight="500"
          >
            Bắt đầu
          </Button>
          <Button
            variant="setup"
            px="24px"
            onClick={() => {
              actionEnd(index);
            }}
            fontSize="md"
            fontWeight="500"
          >
            Kết thúc
          </Button>
          <Button
            variant="setup"
            px="24px"
            onClick={() => {
              actionHelp(index);
            }}
            fontSize="md"
            fontWeight="500"
          >
            Quyền cứu
          </Button>
        </Grid>
      </Flex>
    </Flex>
  );
}
