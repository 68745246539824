import React from 'react';
// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom Components
// Assets
import { useAuthStore } from 'components/zustand/useAuthStore';
import { db } from 'configs/firebase';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { DeleteDocument } from 'modules/firebase/docDocument';
import { useCollection } from 'react-firebase-hooks/firestore';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { matchPath, NavLink, useHistory } from 'react-router-dom';
import CountDownTimer from 'views/user/OlympicViewer/components/CountdownTimer';
import _ from 'lodash';

export default function NavbarLinksUser(props: { secondary: boolean }) {
  const { secondary } = props;
  const history = useHistory();

  const { loggedInUser, clearUser } = useAuthStore((state) => state);
  const groupId = localStorage.getItem(`${loggedInUser.email}-groupId`);
  const [currentDocId, setCurrentDocId] = React.useState<string>('');
  const [dataReports, setDataReports] = React.useState<any>(undefined);

  const refCountdownTimer = React.createRef<any>();

  const collectionRef = doc(db, 'Group', `Group-${groupId}`);

  const questionsRef = query(
    collection(collectionRef, 'questions'),
    orderBy('createdAt', 'desc'),
    limit(1)
  );

  const reportRef = query(
    collection(collectionRef, 'reports'),
    orderBy('createdAt', 'desc'),
    limit(1)
  );

  const [question] = useCollection(questionsRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [reports] = useCollection(reportRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const isShowNavLinks = () => {
    let match = matchPath(window.location.pathname, {
      path: '/user/group/:groupId',
      exact: true,
      strict: false,
    });
    return match ? true : false;
  };

  const isOlympic = () => {
    let match = matchPath(window.location.pathname, {
      path: '/user/group/:groupId/question/:questionId',
      exact: true,
      strict: false,
    });

    return match ? true : false;
  };

  React.useEffect(() => {
    if (isOlympic) {
      refCountdownTimer?.current?.stopTimer();
      refCountdownTimer?.current?.startTimer();
    }
  }, []);

  React.useEffect(() => {
    reports?.docChanges({ includeMetadataChanges: true }).forEach((change) => {
      if (change.type === 'added' || change.type === 'modified') {
        setDataReports(change.doc.data().data);
      }
    });
  }, [reports]);

  React.useEffect(() => {
    if (isOlympic) {
      question
        ?.docChanges({ includeMetadataChanges: true })
        .forEach((change) => {
          if (change.type === 'added') {
            setCurrentDocId(change.doc.id);
            refCountdownTimer?.current?.stopTimer();
            refCountdownTimer?.current?.startTimer();
          }
        });
    }
  }, [question, groupId]);

  const onOverTimer = async () => {
    let ref = doc(collectionRef, 'questions', currentDocId);
    await updateDoc(ref, {
      isEndCountdown: true,
    });
  };

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  // logout
  // const removeLoginUserFirebase = async () => {
  //   const userQuery = query(
  //     collection(db, 'login'),
  //     where('data.email', '==', loggedInUser.email)
  //   );
  //   const User = await getDocs(userQuery);
  //   if (User.docs[0].id) {
  //     const useDoc = doc(db, 'login', User.docs[0]?.id);
  //     await deleteDoc(useDoc).then((res) => {
  //       if (res === undefined) {
  //         history.push('/login');
  //       }
  //     });
  //   }
  // };
  //

  const removeUser_Firebase = () => {
    const queryUserFirebase = query(
      collection(db, 'login'),
      where('data.email', '==', loggedInUser.email)
    );
    getDocs(queryUserFirebase).then((res) => {
      res.docs?.forEach((e) => {
        DeleteDocument({
          nameDocument: 'login',
          idDocument: e.id,
        });
      });
    });
  };
  //=========Xóa user Firebase=========================

  const clearUserFirebase = async () => {
    const userQuery = query(
      collection(db, 'login'),
      where('data.email', '==', loggedInUser.email)
    );
    const User = await getDocs(userQuery);
    if (User.docs[0].id) {
      const useDoc = doc(db, 'login', User.docs[0]?.id);
      await deleteDoc(useDoc).then((res) => {
        if (res === undefined) {
          history.push('/login');
        }
      });
    }
  };

  //================== end Xóa user Firebase=================================

  const LogOut = () => {
    clearUser();
    clearUserFirebase();
    history.push('/login');
  };
  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      {(isShowNavLinks() || isOlympic()) && (
        <Flex gap={'0 50px'} pr="50px">
          <Flex
            bg={ethBg}
            display={secondary ? 'flex' : 'none'}
            borderRadius="30px"
            ms="auto"
            p="6px"
            align="center"
            gap="20px"
            padding="0 20px"
          >
            <Text
              w="max-content"
              color={ethColor}
              fontSize="sm"
              fontWeight="700"
            >
              Thời gian làm bài
            </Text>
            <Flex
              align="center"
              justify="center"
              bg={ethBox}
              h="29px"
              w="auto"
              borderRadius="30px"
              padding={'10px 20px'}
            >
              <CountDownTimer
                seconds={30}
                ref={refCountdownTimer}
                onEndCountdown={onOverTimer}
              />
            </Flex>
          </Flex>
          <Flex
            bg={ethBg}
            display={secondary ? 'flex' : 'none'}
            borderRadius="30px"
            ms="auto"
            p="6px"
            align="center"
            gap="20px"
            padding="10px 20px"
          >
            <Text
              w="max-content"
              color={ethColor}
              fontSize="sm"
              fontWeight="700"
            >
              <Text as="span" display={{ base: 'none', md: 'unset' }}>
                Tổng điểm
              </Text>
            </Text>
            <Flex
              align="center"
              justify="center"
              bg={ethBox}
              h="29px"
              w="auto"
              borderRadius="30px"
              padding={'10px 20px'}
            >
              <Text fontWeight="600">
                {isOlympic()
                  ? _.filter(dataReports, { userId: loggedInUser.id })?.[0]
                      ?.score || 0
                  : 0}
              </Text>
            </Flex>
          </Flex>
          <Flex
            bg={ethBg}
            display={secondary ? 'flex' : 'none'}
            borderRadius="30px"
            ms="auto"
            p="6px"
            align="center"
            gap="20px"
            padding="10px 20px"
          >
            <Text
              w="max-content"
              color={ethColor}
              fontSize="sm"
              fontWeight="700"
            >
              <Text as="span" display={{ base: 'none', md: 'unset' }}>
                Nhóm thi
              </Text>
            </Text>
            <Flex
              align="center"
              justify="center"
              bg={ethBox}
              h="29px"
              w="auto"
              borderRadius="30px"
              padding={'10px 20px'}
            >
              <Text fontWeight="600">{groupId}</Text>
            </Flex>
          </Flex>
        </Flex>
      )}

      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
            src={loggedInUser.avatar}
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pr={loggedInUser.fullName.length > 20 ? '16px' : '0'}
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Chào, {loggedInUser.fullName}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
            >
              <NavLink to="/user/profile-setting">
                <Text fontSize="sm">Quản lý tài khoản</Text>
              </NavLink>
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm" onClick={() => LogOut()}>
                Đăng xuất
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
