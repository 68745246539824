import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { uuid } from "components/functions";
import { RootState } from "constants/rootReducers";
import moment from "moment";
import { Box, useToast } from "@chakra-ui/react";
import { SaveUserAnswerQuiz } from "../../api/SaveUserAnswerQuiz";
import OneChoice from "../QuestionType/OneChoice";
import { submit } from "../../actions";
import QuestionHeader from "../QuestionViewer/QuestionHeader";
import Card from "components/card/Card";
import { useParams } from "react-router-dom";
import Fireworks from "components/Firework";
const correctSound = require("assets/audioGame/tingting.mp3");
const wrongSound = require("assets/audioGame/soundfalse.mp3");

const SlideQuiz = ({
  data,
  currentQuizIndex,
  loggedInUser,
  preview = false,
  previewMode = "ONE",
  hiddenTitle = false,
  isPass,
  isEndCountdown,
  isEndQuestion,
}: any) => {
  //State
  const [isCorrectAnswer, setIsCorrectAnswer] = React.useState<any>(false);
  const [isSubmit, setIsSubmit] = React.useState<any>(false);

  // Ref
  const refFireWork = React.useRef<any>();

  const { groupId }: any = useParams();
  const toast = useToast();

  // Redux
  const dispatch = useDispatch();
  const submittedQuestions = useSelector(
    (state: RootState) => state.OlympicQuizReducer.submittedQuestions
  );
  const questions = data;

  const getFireworksStart = (result: any) => {
    const audio = new Audio();

    if (result) {
      audio.src = correctSound;
      audio.play();
    } else {
      audio.src = wrongSound;
      audio.play();
    }
    return result ? refFireWork?.current.handlerClickStart() : "";
  };

  const detectBrowser = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "Safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "Opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "Edge";
    } else if (userAgent.match(/android/i)) {
      browserName = "Android";
    } else if (userAgent.match(/iphone/i)) {
      browserName = "iPhone";
    } else {
      browserName = "Unknown";
    }

    return browserName;
  };

  const saveQuestionData = (
    question: any,
    userData: any,
    createdAt: number
  ) => {
    const params = {
      ExamGroupId: groupId,
      UserId: loggedInUser.id,
      ExamId: "7121A5FC-711F-4584-AB7C-AF3E3CCC8B29",
      QuestionId: question?.rowId,
      IsCorrect: isPass ? userData?.result?.isCorrect : false,
      Score: userData?.result?.isCorrect ? 100 : 0,
      TakeExamTime: uuid(),
      StudentChoices: userData || null,
      CreatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      Duration: userData?.result?.time - createdAt,
      // AppName: "OLPKids",
      Device: detectBrowser(),
    };
    SaveUserAnswerQuiz(params);
  };

  //Effect
  React.useEffect(() => {
    if (isEndCountdown && !isSubmit) {
      const params = {
        ExamGroupId: groupId,
        UserId: loggedInUser.id,
        ExamId: "7121A5FC-711F-4584-AB7C-AF3E3CCC8B29",
        QuestionId: questions?.[0]?.data.rowId,
        IsCorrect: false,
        Score: 0,
        TakeExamTime: uuid(),
        StudentChoices: "",
        CreatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        Duration: 0,
        Device: detectBrowser(),
      };
      SaveUserAnswerQuiz(params);
    }
  }, [isEndCountdown]);

  //Effect
  React.useEffect(() => {
    if (isEndQuestion) {
      toast({
        title: "Thông báo từ quản trị viên",
        description: `Đáp án ${
          isCorrectAnswer ? "chính xác" : "không chính xác"
        }`,
        status: isCorrectAnswer ? "success" : "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      getFireworksStart(isCorrectAnswer);
    }
  }, [isEndQuestion]);

  // Render
  return (
    <Card height="100%">
      {questions?.map((question: any, i: number) => {
        if (previewMode !== "LIST") {
          if (i !== currentQuizIndex) return null;
        }
        const submittedQuestion = _.find(submittedQuestions, (x: any) => {
          return x.question.id === question.id;
        });
        const questionData = question.data;
        const createdAt = question.createdAt.seconds;
        return (
          <Box key={questionData.id} height="100%">
            <QuestionHeader
              title={questionData.title}
              subTitle={questionData.subTitle}
              questionIndex={questionData?.questionIndex}
            />
            <OneChoice
              data={questionData}
              userData={submittedQuestion?.userData}
              preview={preview}
              studentId={loggedInUser.id}
              hiddenTitle={hiddenTitle}
              loggedInUser={loggedInUser}
              isEndQuestion={isEndQuestion}
              isEndCountdown={isEndCountdown}
              onSubmit={(userData: any) => {
                dispatch(submit({ question, userData }));
                saveQuestionData(questionData, userData, createdAt);
                setIsCorrectAnswer(userData?.result?.isCorrect);
                setIsSubmit(true);
              }}
            />
          </Box>
        );
      })}
      <Fireworks ref={refFireWork} />
    </Card>
  );
};
export default SlideQuiz;
