// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { uuid } from "components/functions";
import QuestionItem from "components/question/QuestionController/QuestionItem";
import { db } from "configs/firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { query } from "helper/QueryHelper";
import _ from "lodash";
import Confirm from "modules/confirm";
import { AddDataDocument } from "modules/firebase/docDocument";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

type TypeMessage =
  | "default"
  | "success"
  | "error"
  | "warning"
  | "info"
  | "loading";

const QuestionController = ({ ...rest }) => {
  const { groupId }: any = useParams();

  const {
    isOpen: isOpenHelp,
    onOpen: onOpenHelp,
    onClose: onCloseHelp,
  } = useDisclosure();
  const {
    isOpen: isOpenSendNoti,
    onOpen: onOpenSendNoti,
    onClose: onCloseSendNoti,
  } = useDisclosure();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const [currentDocQuestionId, setCurrentDocQuestionId] =
    React.useState<string>(undefined);

  //State
  const [questions, setQuestions] = React.useState<any[]>([]);
  const [valueUserRescue, setValueUserRescue] = React.useState<any>(0);
  const [valueNotiContent, setValueNotiContent] = React.useState<string>("");
  const [visibleConfirmEndTest, setVisibleConfirmEndTest] =
    React.useState<boolean>(false);
  //Firebase
  const collectionRef = doc(db, "Group", `Group-${groupId}`);
  const questionRef = collection(collectionRef, "questions");
  const userRef = collection(collectionRef, "users");
  const reportsRef = collection(collectionRef, "reports");
  const messageRef = collection(collectionRef, "messages");
  const toastMessage = useToast();
  const getData = async () => {
    const data = await query("p_GenDe_OlympicSGK_Examinations_DeThi", {
      Lop: 5,
      DeThiSo: 1,
    });

    setQuestions(data?.[0].dataJSON);
  };
  const history = useHistory();
  const setReportsFirebase = async () => {
    await query("p_Examinations_ListStudent_Top", {
      ExamId: "7121A5FC-711F-4584-AB7C-AF3E3CCC8B29",
      ExamGroupId: groupId,
    }).then(async (res) => {
      await addDoc(reportsRef, {
        data: res,
        createdAt: serverTimestamp(),
      }).catch((err) => {
        console.log(err);
      });
    });
  };

  const setUsersFirebase = async () => {
    await query("p_Examinations_ListStudentOfGroup_CheckIsPass ", {
      GroupId: groupId,
      ExamId: "7121A5FC-711F-4584-AB7C-AF3E3CCC8B29",
    })
      .then(async (res) => {
        setValueUserRescue(
          _.filter(res, function (o) {
            if (!o.isPass) return o;
          }).length
        );
        await addDoc(userRef, {
          data: res,
          createdAt: serverTimestamp(),
        }).catch((err) => {
          console.log(err);
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  const addMessages = async (data: string, typeMessage: TypeMessage) => {
    await query("p_Examinations_Message_InsertUpdate", {
      Id: null,
      GroupId: 38,
      Title: "Thông báo từ quản trị viên",
      Body: data,
      Icon: null,
      Color: null,
      Type: typeMessage,
      CreatedBy: 1,
    }).then(async () => {
      AddDataDocument({
        nameDocument: "Group",
        idDocument: `Group-${groupId}`,
        nameCollection: "message",
        parameters: {
          data,
          typeMessage,
        },
      });
    });
  };

  const handleStart = async (index: number) => {
    // Thêm câu hỏi vào từng group theo Id

    AddDataDocument({
      nameDocument: "Group",
      idDocument: `Group-${groupId}`,
      nameCollection: "questions",
      parameters: {
        data: { ...questions?.[index], questionIndex: index + 1 },
        isEndCountdown: false,
        isEndQuestion: false,
      },
    }).then((docRef) => {
      setCurrentDocQuestionId(docRef?.id);
      addMessages(`Bắt đầu câu hỏi số ${index + 1}`, "success");
    });
  };

  const handleEnd = async (index: number) => {
    await query("p_Question_Answer_Get ", {
      QuestionId: questions?.[index]?.rowId,
      ModuleId: null,
    }).then((res) => {
      let questionDocsRef = doc(questionRef, currentDocQuestionId);
      updateDoc(questionDocsRef, {
        data: {
          ...questions?.[index],
          questionIndex: index + 1,
          answers: res?.[0]?.answers,
        },
        isEndQuestion: true,
      }).then(() => {
        setUsersFirebase();
        setReportsFirebase();
        // addMessages(`Kết thúc  câu hỏi số ${index + 1}`, "warning");
      });
    });
  };

  const handleHelp = () => {
    if (valueUserRescue > 0) {
      const parameters = {
        ExamId: "7121A5FC-711F-4584-AB7C-AF3E3CCC8B29",
        ExamGroupId: groupId,
        TotalUserRescue: valueUserRescue,
      };
      query("p_Examinations_Student_Rescue", parameters).then(() => {
        setUsersFirebase();
        setReportsFirebase();
        addMessages(
          `Có ${valueUserRescue} thí sinh vừa được cứu sau câu hỏi này `,
          "info"
        );
        onCloseHelp();
      });
    } else {
      toastMessage({
        title: "Thông báo từ hệ thống",
        description: "Số lượng cứu phải lớn hơn 0",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleSendNoti = async () => {
    await addMessages(valueNotiContent, "info");
    onCloseSendNoti();
  };

  return (
    <React.Fragment>
      <FormControl>
        <Card p="30px" {...rest}>
          <Flex justify="space-between" align="center" gap="10px">
            <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
              Question Controller (GROUP: {groupId} )
            </Text>
            <Flex gap="10px">
              <Button
                variant="darkBrand"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: "128px", md: "148px" }}
                h="46px"
                ms="auto"
                onClick={() => {
                  onOpenSendNoti();
                }}
              >
                Gửi thông báo
              </Button>
              {/* <Button
                colorScheme="red"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: "128px", md: "148px" }}
                h="46px"
                ms="auto"
                onClick={() => {
                  setVisibleConfirmEndTest(true);
                }}
              >
                Kết thúc thi
              </Button> */}
            </Flex>
          </Flex>
          <SimpleGrid
            columns={{ sm: 1, md: 1, xl: 1 }}
            spacing={{ base: "20px", xl: "0px" }}
          >
            {questions.map((item, index) => (
              <QuestionItem
                key={index}
                index={index}
                py="24px"
                borderBottom="1px solid"
                borderColor={borderColor}
                name={
                  item.question
                    ? `${index + 1}. ${item.question}`
                    : `${index + 1}. ${item.title}`
                }
                value=""
                actionStart={() => {
                  handleStart(index);
                }}
                actionEnd={() => {
                  handleEnd(index);
                }}
                actionHelp={() => {
                  onOpenHelp();
                }}
              />
            ))}
          </SimpleGrid>
        </Card>
      </FormControl>
      <Modal isOpen={isOpenHelp} onClose={onCloseHelp}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cứu thí sinh</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Nhập số thí sinh được cứu</FormLabel>
              <NumberInput
                defaultValue={valueUserRescue}
                min={0}
                max={valueUserRescue}
                onChange={(valueString) =>
                  setValueUserRescue(Number(valueString))
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleHelp} colorScheme="blue" mr={3}>
              Cứu
            </Button>
            <Button onClick={onCloseHelp}>Đóng</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenSendNoti} onClose={onCloseSendNoti}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gửi thông báo</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Nội dung thông báo</FormLabel>
              <Input
                onChange={(e) => {
                  setValueNotiContent(e.target.value);
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleSendNoti} colorScheme="blue" mr={3}>
              Thông báo
            </Button>
            <Button onClick={onCloseSendNoti}>Đóng</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default QuestionController;
