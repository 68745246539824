import React from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { db } from 'configs/firebase'
import { addDoc, collection, deleteDoc, doc, getDocs, limit, orderBy, query, serverTimestamp, updateDoc } from 'firebase/firestore'

type PropsDocument = {
  nameDocument: string
  idDocument?: string
  nameCollection?: string
}
type PropsCollection = {
  nameCollection: string
}
type PropsDeleteCollection = {
  nameDocument?: string
  idDocument?: string
  nameCollection?: string
}
type PropsAddDataCollection = {
  nameCollection: string
  parameters: any
}
type PropsAddDataDocument = {
  nameDocument: string
  idDocument: string
  nameCollection: string
  parameters: any
}
type PropsUpdateCollection = {
  nameCollection: string
  id: string
  parameters: any
}

const DocDocument = ({ nameDocument, idDocument = null, nameCollection }: PropsDocument) => {
  const document = doc(db, nameDocument, idDocument)
  //
  const docCollectionRef = query(collection(document, nameCollection), orderBy('createdAt', 'desc'))
  const [data, isLoading, error] = useCollection(docCollectionRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  })
  return { data, isLoading, error }
}

const FirstDocDocument = ({ nameDocument, idDocument = null, nameCollection }: PropsDocument) => {
  const document = doc(db, nameDocument, idDocument)
  //
  const docCollectionRef = query(collection(document, nameCollection), orderBy('createdAt', 'desc'), limit(1))
  const [data, isLoading, error] = useCollection(docCollectionRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  })
  return { data, isLoading, error }
}

const AddDataCollection = ({ nameCollection, parameters }: PropsAddDataCollection) => {
  return new Promise<any>((resolve, reject) => {
    const Ref = collection(db, nameCollection)
    addDoc(Ref, { ...parameters, createdAt: serverTimestamp() })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
const AddDataDocument = ({ nameCollection, nameDocument, idDocument, parameters }: PropsAddDataDocument) => {
  return new Promise<any>((resolve, reject) => {
    const documentRef = doc(db, nameDocument, idDocument)
    const Ref = collection(documentRef, nameCollection)
    addDoc(Ref, { ...parameters, createdAt: serverTimestamp() })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const DocCollection = ({ nameCollection }: PropsCollection) => {
  const docCollectionRef = collection(db, `${nameCollection}`)
  const messageQueryRef = query(docCollectionRef, orderBy('createdAt', 'desc'))
  const [data, isLoading, error] = useCollection(messageQueryRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  })

  return { data, isLoading, error }
}
const DeleteCollection = async ({ nameCollection }: PropsDeleteCollection) => {
  const ref = collection(db, nameCollection)
  const querySnapshot = await getDocs(ref)
  querySnapshot?.forEach((doc) => {
    deleteDoc(doc.ref)
  })
}
const DeleteDocument = async ({ nameDocument, idDocument }: PropsDeleteCollection) => {
  const ref = doc(db, nameDocument, idDocument)
  deleteDoc(ref)
}
const UpdateCollection = ({ nameCollection, parameters, id }: PropsUpdateCollection) => {
  const collectionRef = collection(db, nameCollection)
  let loginDocRef = doc(collectionRef, id)
  updateDoc(loginDocRef, parameters)
}

export { DocDocument, FirstDocDocument, DocCollection, DeleteCollection, AddDataCollection, AddDataDocument, UpdateCollection, DeleteDocument }
