// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Icon,
  useColorModeValue,
  FormLabel,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAuthStore } from 'components/zustand/useAuthStore';
import * as cryptoJS from 'crypto-js';
import { query } from 'helper/QueryHelper';
import { useToast } from '@chakra-ui/react';

export default function ChangePassword() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const textColor = useColorModeValue('navy.700', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // variable
  const toast = useToast();
  //zustand
  const { loggedInUser } = useAuthStore((state) => state);
  const [disableButton, setDisableButton] = useState(false);

  // yup
  const Schema = yup.object().shape({
    password: yup
      .string()
      .required('Vui lòng nhập mật khẩu')
      .min(6, 'Mật khẩu ít nhất 6 ký tự'),
    confirmPassword: yup
      .string()
      .required('Vui lòng nhập mật khẩu')
      .oneOf([yup.ref('password'), null], 'Nhập lại mật khẩu không trùng khớp'),
  });
  // hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });
  // send form
  const onsubmit = async (values: any) => {
    const passwordMD5 = cryptoJS.MD5(values.password).toString();

    const data = {
      Id: loggedInUser.id,
      Password: passwordMD5,
    };
    if (values) {
      const response = await query('p_System_User_UpdatePassword', data);
      if (response[0]?.userId > 0) {
        const message = response[0]?.message;
        reset();
        toast({
          title: message,
          description: '',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
          containerStyle: {
            mt: '40px',
          },
        });
        setDisableButton(true);
      }
    }
  };
  useEffect(() => {
    if (disableButton) {
      const timer = setTimeout(() => {
        setDisableButton(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [disableButton]);
  return (
    <>
      <form onSubmit={handleSubmit(onsubmit)}>
        <FormControl>
          <Card>
            <Flex direction="column" mb="40px" ms="10px">
              <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                Đổi mật khẩu
              </Text>
              {/* <Text fontSize="md" color={textColorSecondary}>
            Here you can set your new password
          </Text> */}
            </Flex>
            <FormControl>
              <Flex flexDirection="column">
                {/* =============password====================== */}
                <FormLabel
                  ms="10px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  htmlFor="password"
                >
                  Mật khẩu mới
                  <Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    fontSize="sm"
                    ms={{ base: '0px', md: '4px' }}
                    placeholder="Mật khẩu ít nhất 6 ký tự"
                    mb="24px"
                    size="lg"
                    type={show ? 'text' : 'password'}
                    variant="auth"
                    id="password"
                    {...register('password')}
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                <Text
                  fontSize={'sm'}
                  mb="10px"
                  color={'red'}
                  ml="10px"
                  mt={'-10px'}
                >
                  {errors?.password?.message}
                </Text>
                {/* ==============retype password================ */}
                <FormLabel
                  ms="10px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  htmlFor="retype-password"
                >
                  Nhập lại mật khẩu mới
                  <Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    fontSize="sm"
                    ms={{ base: '0px', md: '4px' }}
                    placeholder="Mật khẩu ít nhất 6 ký tự"
                    mb="24px"
                    size="lg"
                    type={show ? 'text' : 'password'}
                    variant="auth"
                    id="retype-password"
                    {...register('confirmPassword')}
                  />
                </InputGroup>

                <Text
                  fontSize={'sm'}
                  mb="10px"
                  color={'red'}
                  ml="10px"
                  mt={'-10px'}
                >
                  {errors?.confirmPassword?.message}
                </Text>
              </Flex>
            </FormControl>
            <Button
              variant="brand"
              minW="183px"
              fontSize="sm"
              fontWeight="500"
              ms="auto"
              type="submit"
              isDisabled={disableButton}
              _hover={{ bg: '#7551FF' }}
            >
              Cập nhật mật khẩu
            </Button>
          </Card>
        </FormControl>
      </form>
    </>
  );
}
