import { sachSoUploadFile, sachsoServerUrl } from 'constants/serverUrls';
import { amesSendMailUrl } from 'constants/serverUrls';

import axios from 'axios';

import _ from 'lodash';
import { MODULE_ID } from 'constants/settings';

const authorizationKey = 'Basic 12C1F7EF9AC8E288FBC2177B7F54D';
const ApplicationName = 'OLPKids';

function query(sqlCommand: string, parameters = {}): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/query`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: ApplicationName,
      },
      data: {
        sqlCommand,
        parameters: { ...parameters, moduleId: MODULE_ID },
      },
    })
      .then((res) => {
        resolve(res.data.results);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
function queryNotModule(sqlCommand: string, parameters = {}): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/query`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: ApplicationName,
      },
      data: {
        sqlCommand,
        parameters: { ...parameters },
      },
    })
      .then((res) => {
        resolve(res.data.results);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
function amesSendMail(url: string, data: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${amesSendMailUrl}/${url}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: authorizationKey,
        ApplicationName: 'EBM',
        'Content-Type': 'application/json',
      },
      data,
    })
      .then((res) => {
        // console.log('🚀 ~ res.data:', res.data);
        resolve(res.data);
      })
      .catch((error: any) => {
        console.log('🚀 ~ error:', error);
        reject(error);
      });
  });
}
function uploadFile(DATA: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachSoUploadFile}`,
      method: 'POST',
      headers: {
        Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
        ApplicationName: 'SACHSO',
      },
      data: DATA,
    })
      .then((res) => {
        // console.log('🚀 ~ res', res.data.files[0]);
        resolve(res.data.files[0]);
      })
      .catch((error: any) => {
        console.log('🚀 ~ error:', error);
        reject(error);
      });
  });
}
export { query, queryNotModule, amesSendMail, uploadFile };
