import { Box, Portal, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import Footer from 'components/footer/FooterAdmin';
import UserNavbar from 'components/navbar/NavbarUser';
import { SidebarContext } from 'contexts/SidebarContext';
import { Route, Switch, useHistory } from 'react-router';
import routesStudent from 'routesStudent';
import { DeleteDocument, DocCollection } from 'modules/firebase/docDocument';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from 'configs/firebase';
import { useAuthStore } from 'components/zustand/useAuthStore';
import { useCollection } from 'react-firebase-hooks/firestore';
import routes from 'routesStudent';

const UserLayout = (props: { [x: string]: any }) => {
  const { ...rest } = props;
  const [fixed] = React.useState(false);
  const [toggleSidebar, setToggleSidebar] = React.useState(false);
  const { data: userLogin } = DocCollection({
    nameCollection: 'login',
  });
  const { login, loggedInUser, loading, message, resetMessage } = useAuthStore(
    (state) => state
  );
  const notification = useToast();

  const history = useHistory();
  const messageFromAdminRef = collection(db, 'messageToStudent');
  const [messageDivideGroup] = useCollection(messageFromAdminRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const removeUserById_Firebase = async () => {
    const queryUserFirebase = query(
      collection(db, 'login'),
      where('data.email', '==', loggedInUser.email)
    );
    getDocs(queryUserFirebase).then((res) => {
      res.docs?.forEach((e) => {
        DeleteDocument({
          nameDocument: 'login',
          idDocument: e.id,
        });
      });
    });
  };
  const removeMessageByStudentId_Firebase = () => {
    const queryUserFirebase = query(
      collection(db, 'messageToStudent'),
      where('toStudent', '==', loggedInUser.email)
    );
    getDocs(queryUserFirebase).then((res) => {
      res.docs?.forEach((e) => {
        DeleteDocument({
          nameDocument: 'messageToStudent',
          idDocument: e.id,
        });
      });
    });
  };

  React.useEffect(() => {
    messageDivideGroup
      ?.docChanges({ includeMetadataChanges: true })
      .forEach((change: any) => {
        if (change.type === 'added' || change.type === 'modified') {
          const { data, toStudent, type } = change.doc.data();
          if (toStudent.toUpperCase() === loggedInUser?.email.toUpperCase()) {
            if (type === 'remove') {
              notification({
                title: 'Thông báo từ hệ thống',
                description: data,
                status: 'info',
                duration: 5000,
                // id: data.data.id,

                isClosable: true,
                position: 'top',
                onCloseComplete() {
                  removeUserById_Firebase();
                  removeMessageByStudentId_Firebase();
                  history.push('/login');
                },
              });
            }
          }
        }
      });
  }, [messageDivideGroup]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', () => {
      removeUserById_Firebase();
    });
  }, []);

  // React.useEffect(() => {
  //   const queryUserFirebase = query(collection(db, 'login'), where('data.email', '==', loggedInUser.email), limit(1))
  //   getDocs(queryUserFirebase).then((res) => {
  //     const id = res?.docs?.[0]?.id
  //     if (res?.docs?.length === 0) {
  //       if (!notification.isActive(res?.docs?.[0]?.data()?.data?.id)) {
  //         notification({
  //           title: 'Thông báo từ hệ thống',
  //           description: 'Tài khoản của bạn đã bị xóa khỏi hệ thống.',
  //           status: 'info',
  //           duration: 3000,
  //           isClosable: true,
  //           id: res?.docs?.[0]?.data()?.data?.id,
  //           position: 'top',
  //           onCloseComplete() {
  //             history.push('/login')
  //           },
  //         })
  //       }
  //     }
  //   })
  // }, [userLogin])

  const { onOpen } = useDisclosure();
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: number) => {
      if (route.layout === '/user') {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };
  // check user Roles
  useEffect(() => {
    if (!loggedInUser) {
      history.push('/login');
      return;
    }
  }, [loggedInUser]);

  return (
    <React.Fragment>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Portal>
            <Box>
              <UserNavbar
                onOpen={onOpen}
                fixed={fixed}
                brandText={getActiveRoute(routes)}
                // secondary={getActiveNavbar(routes)}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>{getRoutes(routesStudent)}</Switch>
            </Box>
          ) : null}
          {/* <Box>
            <Footer />
          </Box> */}
        </SidebarContext.Provider>
      </Box>
    </React.Fragment>
  );
};

export default UserLayout;
