import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { query } from 'helper/QueryHelper';

type LoggedInUserEntity = {
  id: number;
  fullName: string;
  email: string;
  cityId: string;
  districtId: string;
  rowId: string;
  phone: string;
  grade: string;
  userRoles: string;
  sex: string | boolean;
  schoolId: string;
  avatar: string | null;
};

interface AuthState {
  loggedInUser: LoggedInUserEntity | null;
  loading: boolean;
  error: unknown;
  message: string;
  idFirebase: string;
  login: (Email: any, Password: any) => void;
  resetMessage: () => void;
  clearUser: () => void;
  getIdFirebase: (idFirebase: any) => void;
  updateUser: ({ user }: any) => void;
  updateAvatar: (avatar: any) => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set, get) => ({
        loading: false,
        error: null,
        loggedInUser: null,
        message: null,
        idFirebase: null,
        login: async (Email, Password) => {
          const data = {
            Email,
            Password,
          };

          try {
            set({ loading: true }, false, 'AUTH/LOGIN/LOADING');
            const response: any = await query('p_System_User_Login', data);
            // console.log('🚀 ~ response:', response);
            set(
              {
                loggedInUser: response[0]?.data[0],
                loading: false,
                message: response[0].message,
              },
              false,
              'AUTH/LOGIN/SUCCESS'
            );
          } catch (error) {
            set(
              (state) => ({ ...state, error: error, loading: false }),
              true,
              'AUTH/LOGIN/FAILURE'
            );
          }
        },
        resetMessage: () => {
          set({ message: null });
        },
        clearUser: () => {
          set({ loggedInUser: null });
        },
        getIdFirebase: (idFirebase: any) => {
          set({
            idFirebase: idFirebase,
          });
        },
        updateUser: (user: any) => {
          set((state): any => ({
            loggedInUser: {
              ...state.loggedInUser,
              email: user.email ? user.email : state.loggedInUser.email,
              fullName: user.fullName,
              phone: user.phone,
              sex: user.sex,
              cityId: user.city,
              districtId: user.district,
              schoolId: user.schoolId | 0,
            },
          }));
        },
        updateAvatar: (avatar: any) => {
          set((state): any => ({
            loggedInUser: {
              ...state.loggedInUser,
              avatar: avatar,
            },
          }));
        },
      }),
      {
        name: 'auth-storage',
      }
    )
  )
);
