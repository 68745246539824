// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from 'components/card/Card';
import { useAuthStore } from 'components/zustand/useAuthStore';
import { query, queryNotModule } from 'helper/QueryHelper';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export default function AccountSettings() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColor = useColorModeValue('navy.700', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  // variable
  const [cityList, setCityList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const toast = useToast();

  //zustand
  const { loggedInUser, updateUser } = useAuthStore((state) => state);
  const [cityId, setCityId] = useState(loggedInUser.cityId);
  const [districtId, setDistrictId] = useState(loggedInUser.districtId);
  const [schoolId, setSchoolId] = useState(loggedInUser.schoolId);

  const [schoolList, setSchoolList] = useState([]);
  const [gender, setGender] = useState(loggedInUser?.sex?.toString());
  const [disableButton, setDisableButton] = useState(false);

  // yup
  const Schema = yup.object().shape({
    fullName: yup.string().required('Vui lòng nhập đầy đủ họ và tên'),
    phone: yup
      .string()
      .required('Vui lòng nhập số điện thoại')
      .matches(
        /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
        'Số điện thoại không hợp lệ'
      ),
    email: yup
      .string()
      .required('Vui lòng nhập email')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Vui lòng nhập đúng định dạng email'
      ),
    city: yup.string().required('Vui lòng chọn thành phố'),
    district: yup.string().required('Vui lòng chọn quận huyện'),
    sex: yup.string().required('Vui lòng chọn giới tính'),
  });
  const defaultValue = {
    fullName: loggedInUser.fullName,
    phone: loggedInUser.phone,
    email: loggedInUser.email,
    city: loggedInUser.cityId,
    district: loggedInUser.districtId,
    sex: loggedInUser.sex,
    schoolId: loggedInUser.schoolId,
  };
  // hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: defaultValue,
  });

  // get city list
  const getCityList = async () => {
    const res = await queryNotModule('p_METADATA_Cities_Get');
    setCityList(res);
  };

  // get district list
  const getDistrictList = async () => {
    if (cityId !== null) {
      const res = await queryNotModule('p_METADATA_Districts_GetByCity', {
        cityId,
      });
      setDistrictList(res);
    }
  };
  // get school
  const getSchool = async () => {
    if (Number(cityId) > 0 && Number(districtId) > 0) {
      const data = {
        cityId,
        districtId,
      };
      const response = await queryNotModule(
        'p_METADATA_Schools_GetByCity_District',
        data
      );
      if (response && response.length > 0) {
        setSchoolList(response);
      }
    }
  };

  useEffect(() => {
    getCityList();
    getDistrictList();
    getSchool();
  }, [cityId, districtId]);

  useEffect(() => {
    if (disableButton) {
      const timer = setTimeout(() => {
        setDisableButton(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [disableButton]);

  //send form
  const onSubmit = async (values: any) => {
    const data = {
      Id: loggedInUser.id,
      FullName: values.fullName,
      Email: values.email,
      Phone: values.phone,

      CityId: values.city,
      DistrictId: values.district,

      Sex: values.sex === 'true' ? true : false,
      UserName: '',
      SchoolId: values.schoolId | 0,
      Note: '',

      CreatedBy: '',
      SchoolTypeId: 0,
      Grade: 0,
      AreaId: 0,
    };
    if (values) {
      const response = await query('p_System_User_InsertUpdate', data);
      if (response && response[0]?.userId > 0) {
        toast({
          title: 'Cập nhật tài khoản thành công !',
          description: '',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
          containerStyle: {
            mt: '40px',
          },
        });
        updateUser(values);

        setDisableButton(true);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Card>
          <Flex direction="column" mb="40px" ms="10px">
            <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
              Thông tin tài khoản
            </Text>
            {/* <Text fontSize="md" color={textColorSecondary}>
            Here you can change user account information
          </Text> */}
          </Flex>
          <SimpleGrid
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: '20px', xl: '20px' }}
          >
            {/* =======Full Name=============== */}
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="fullName"
              >
                Họ và tên<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id="fullname"
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="text"
                fontWeight="500"
                size="lg"
                defaultValue={loggedInUser.fullName}
                {...register('fullName')}
              />
              <Text fontSize={'sm'} mb="24px" mt="5px" color={'red'} ml="5px">
                {errors?.fullName?.message}
              </Text>
            </FormControl>

            {/* =======Email=============== */}
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="email"
              >
                Email
              </FormLabel>
              <Input
                id="email"
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="text"
                fontWeight="500"
                size="lg"
                isReadOnly={true}
                defaultValue={loggedInUser.email}
                {...register('email')}
                bg="gray.300"
              />
              <Text fontSize={'sm'} mb="24px" mt="5px" color={'red'} ml="5px">
                {errors?.email?.message}
              </Text>
            </FormControl>

            {/* =======Phone=============== */}
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="phone"
              >
                Số điện thoại<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id="phone"
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="text"
                fontWeight="500"
                size="lg"
                defaultValue={loggedInUser.phone}
                {...register('phone')}
              />
              <Text fontSize={'sm'} mb="24px" mt="5px" color={'red'} ml="5px">
                {errors?.phone?.message}
              </Text>
            </FormControl>
            {/* =================Sex========================= */}
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                htmlFor="sex"
              >
                Giới Tính<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                ms={{ base: '0px', md: '4px' }}
                size="lg"
                variant="auth"
                fontSize="sm"
                id="sex"
                defaultValue={gender === 'true' ? 'Nam' : 'Nữ'}
                {...register('sex')}
                onChange={(e: any) => setGender(e.target.value)}
              >
                <option value={'true'}>Nam</option>
                <option value={'false'}>Nữ</option>
              </Select>
              <Text fontSize={'sm'} mb="24px" mt="5px" color={'red'} ml="5px">
                {errors?.sex?.message}
              </Text>
            </FormControl>

            {/* =======City=============== */}

            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                htmlFor="city"
              >
                Thành Phố<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                ms={{ base: '0px', md: '4px' }}
                size="lg"
                variant="auth"
                fontSize="sm"
                id="city"
                value={cityId}
                {...register('city')}
                onChange={(e: any) => setCityId(e.target.value)}
              >
                {cityList && cityList.length > 0 ? (
                  cityList.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    );
                  })
                ) : (
                  <React.Fragment />
                )}
              </Select>
              {cityId === null ? (
                <Text fontSize={'sm'} mb="24px" mt="5px" color={'red'} ml="5px">
                  {errors?.city?.message}
                </Text>
              ) : (
                <Text
                  fontSize={'xs'}
                  mb="24px"
                  mt="5px"
                  color={'red'}
                  ml="5px"
                ></Text>
              )}
            </FormControl>

            {/* =======district=============== */}

            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                htmlFor="district"
              >
                Quận huyện<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                ms={{ base: '0px', md: '4px' }}
                size="lg"
                variant="auth"
                fontSize="sm"
                id="district"
                value={districtId}
                {...register('district')}
                onChange={(e: any) => setDistrictId(e.target.value)}
              >
                {districtList && districtList.length > 0 ? (
                  districtList.map((district) => {
                    return (
                      <option key={district.id} value={district.id}>
                        {district.name}
                      </option>
                    );
                  })
                ) : (
                  <React.Fragment />
                )}
              </Select>
              {cityId === null ? (
                <Text fontSize={'sm'} mb="24px" mt="5px" color={'red'} ml="5px">
                  {errors?.city?.message}
                </Text>
              ) : (
                <Text
                  fontSize={'sm'}
                  mb="24px"
                  mt="5px"
                  color={'red'}
                  ml="5px"
                ></Text>
              )}
            </FormControl>

            {/* ======School=============== */}
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                htmlFor="SchoolId"
              >
                Trường học
              </FormLabel>
              <Select
                ms={{ base: '0px', md: '4px' }}
                size="lg"
                variant="auth"
                fontSize="sm"
                id="SchoolId"
                value={schoolId}
                {...register('schoolId')}
                onChange={(e) => setSchoolId(e.target.value)}
              >
                {Number(cityId) > 0 && Number(districtId) > 0 ? (
                  <option value="" hidden>
                    Vui lòng chọn trường
                  </option>
                ) : (
                  <option value="">
                    vui lòng chọn thành phố và quận huyện
                  </option>
                )}

                {schoolList && schoolList.length > 0 ? (
                  schoolList.map((school) => {
                    return (
                      <option value={school.id} key={school.id}>
                        {school.name}
                      </option>
                    );
                  })
                ) : (
                  <React.Fragment />
                )}
              </Select>
            </FormControl>
          </SimpleGrid>
          {/* <InputField mb="25px" id="FullName" label="FullName" /> */}

          {/* <TextField
          id="about"
          label="About Me"
          h="100px"
          placeholder="Tell something about yourself in 150 characters!"
        /> */}

          <Button
            variant="brand"
            minW="183px"
            fontSize="sm"
            fontWeight="500"
            ms="auto"
            type="submit"
            isDisabled={disableButton}
            _hover={{ bg: '#7551FF' }}
          >
            Cập nhật tài khoản
          </Button>
        </Card>
      </FormControl>
    </form>
  );
}
