export default {
    purple: '#6474FC',
    dimGray: '#666666',
    greyChateau: '#A5A8AB',
    dodgerBlue: '#1179EF',
    lightAzure: '#5AA9FF',
    darkChambray: '#365992',
    darkTangerine: '#FFAB1A',
    green: '#2ece76',
    warning: '#C63200',
    font: '#32325d',
    border: '#d9d9d9',
    gold: '#FFAF24',
    primary: '#006EE6',
  
    gray: {
      100: '#f6f9fc',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#8898aa',
      700: '#525f7f',
      800: '#32325d',
      900: '#212529',
    },
    theme: {
      default: '#172b4d',
      primary: '#006EE6',
      secondary: '#f4f5f7',
      info: '#11cdef',
      success: '#2dce89',
      danger: '#f5365c',
      warning: '#FFAF24',
      gold: '#FFAF24',
      successBold: '#52C41A',
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent',
  };
  