import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useAuthStore } from "components/zustand/useAuthStore";
import { db } from "configs/firebase";
import {
  collection,
  doc,
  query as firebaseQuery,
  getDocs,
  where,
  orderBy,
} from "firebase/firestore";
import { query } from "helper/QueryHelper";
import _ from "lodash";
import { DocCollection, UpdateCollection } from "modules/firebase/docDocument";
import React, { useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { IoCloseCircle } from "react-icons/io5";
import { useHistory } from "react-router-dom";

// import { DocCollection } from 'modules/firebase/docDocument'
// import { docCollection } from 'modules/firebase/docDocument'

const IndexWaitingRoom = (props: any) => {
  const [dataStudentJoinRoom, setDataStudentJoinRoom] = React.useState<any>([]);
  const [statusReady, setStatusReady] = React.useState<number>(0);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const { login, loggedInUser, loading, message, resetMessage } = useAuthStore(
    (state) => state
  );
  const { idFirebase } = props.location.state;
  const [isOnlineFirebase, setIsOnlineFirebase] = React.useState(false);

  const messageFromAdminRef = collection(db, "messageToStudent");
  const [messageDivideGroup] = useCollection(messageFromAdminRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const toastMessage = useToast();
  const history = useHistory();

  const { data: userLogin } = DocCollection({
    nameCollection: "login",
  });

  React.useEffect(() => {
    userLogin
      ?.docChanges({ includeMetadataChanges: true })
      .forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          const { data, timeLogin, status } = change.doc.data();
          _.remove(dataStudentJoinRoom, {
            email: data.email,
          });
          setDataStudentJoinRoom((prev: any) => [
            ...prev,
            {
              data,
              email: data.email,
              timeLogin,
              status,
            },
          ]);
          if (loggedInUser.email === data.email) {
            setStatusReady(status);
          }
        }
      });
  }, [userLogin]);

  React.useEffect(() => {
    messageDivideGroup
      ?.docChanges({ includeMetadataChanges: true })
      .forEach((change: any) => {
        if (change.type === "added" || change.type === "modified") {
          const { data, toStudent, groupId, type } = change.doc.data();
          if (toStudent.toUpperCase() === loggedInUser?.email.toUpperCase()) {
            if (type === "divideGroup") {
              setLoading(true);
              toastMessage({
                title: "Thông báo từ quản trị viên",
                description: data,
                status: "success",
                duration: 5000,
                id: data?.data?.id,
                isClosable: true,
                position: "top",
              });
              localStorage.setItem(
                `${loggedInUser.email}-groupId`,
                JSON.stringify(groupId)
              );
              setTimeout(() => {
                history.push(`/user/group/${groupId}`, {
                  username: loggedInUser.email,
                  idFirebase,
                });
              }, 3000);
            }
          }
        }
      });
  }, [messageDivideGroup]);
  // Hủy sẵn sàng
  const handleUnReady = () => {
    setStatusReady(0);
    UpdateCollection({
      nameCollection: "login",
      id: idFirebase,
      parameters: {
        status: 0,
      },
    });
  };

  // React.useEffect(() => {
  // Quay lại tabs
  // setStatusReady(4)
  // window.addEventListener('focus', () => {
  //   updateDoc(loginDocRef, {
  //     status: 4,
  //   })
  // })
  // // Đóng trang
  // setStatusReady(2)
  // window.addEventListener('beforeunload', () => {
  //   updateDoc(loginDocRef, {
  //     status: 2,
  //   })
  // })
  // // chuyển tab
  // setStatusReady(3)
  // document.addEventListener('visibilitychange', () => {
  //   updateDoc(loginDocRef, {
  //     status: 3,
  //   })
  // })
  // }, [])

  // Check firebase

  const checkOnlineFirebase = async () => {
    const userQuery = firebaseQuery(
      collection(db, "login"),
      where("data.email", "==", loggedInUser.email),
      orderBy("createdAt")
    );

    const User = await getDocs(userQuery);
    if (User.docs.length < 1) {
      setIsOnlineFirebase(true);
      return;
    }
    setIsOnlineFirebase(false);
  };

  useEffect(() => {
    checkOnlineFirebase();
    if (isOnlineFirebase) {
      console.log("🚀 ~ isOnlineFirebase:", isOnlineFirebase);
      history.push("/login");
    }
  }, [isOnlineFirebase, checkOnlineFirebase]);

  // End Check firebase

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} px={"20px"}>
      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          height="100vh"
          direction="column"
        >
          <Spinner color="green.500" size="xl" />
          <Text marginTop={10} fontSize="md">
            ĐANG TIẾN HÀNH CHUYỂN ĐẾN NHÓM CỦA BẠN ...
          </Text>
        </Flex>
      ) : (
        <>
          <Center>
            <Heading fontSize="lg" textTransform={"uppercase"}>
              Phòng chờ chia nhóm
            </Heading>
          </Center>
          <Wrap>
            {dataStudentJoinRoom?.map((item: any, index: number) => {
              return (
                <WrapItem padding="20px 0">
                  <Card
                    border={`2px solid ${
                      item?.email === loggedInUser?.email
                        ? "#576CBC"
                        : item?.status !== 0
                        ? " #68D391"
                        : "#E53E3E"
                    }`}
                  >
                    <Center>
                      <CardHeader>
                        <Flex>
                          <Flex
                            flex="1"
                            gap="4"
                            alignItems="center"
                            flexWrap="wrap"
                          >
                            <Avatar
                              name={item?.data?.fullName}
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1JTS3RJUI4wkYE9x7QNC18tQk4OlwdTKaq8Uchw4n6w&s"
                            />
                            <Box>
                              <Heading size="sm">
                                {item?.data?.fullName}
                              </Heading>
                              <Text>{item?.data?.email}</Text>
                            </Box>
                          </Flex>
                        </Flex>
                      </CardHeader>
                    </Center>
                  </Card>
                </WrapItem>
              );
            })}
          </Wrap>
        </>
      )}
    </Box>
  );
};

export default IndexWaitingRoom;
