import { query } from "helper/QueryHelper";
import { UserAnswerProps } from "views/user/type";



export const SaveUserAnswerQuiz = (params: UserAnswerProps) => {
    query(
      "p_Examinations_Student_Log_Insert",
      params
    ).catch((err: any) => {
      console.log(err);
    });
  };