import React, { useEffect } from 'react';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  useToast,
} from '@chakra-ui/react';
// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuthStore } from 'components/zustand/useAuthStore';
import { yupResolver } from '@hookform/resolvers/yup';
import * as cryptoJS from 'crypto-js';
import { AddDataCollection } from 'modules/firebase/docDocument';
import moment from 'moment';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  serverTimestamp,
  where,
  orderBy,
} from 'firebase/firestore';
import { db } from 'configs/firebase';

export default function UserLogin() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  // variable
  const [disableButton, setDisableButton] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState(null);

  const history = useHistory();
  const toast = useToast();

  // zustand
  const {
    login,
    loggedInUser,
    getIdFirebase,
    idFirebase,
    message,
    resetMessage,
  } = useAuthStore((state) => state);

  // yup
  const Schema = yup.object().shape({
    email: yup
      .string()
      .required('Vui lòng nhập email')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Vui lòng nhập đúng định dạng email'
      ),
    password: yup
      .string()
      .required('Vui lòng nhập mật khẩu')
      .min(6, 'Mật khẩu ít nhất 6 ký tự'),
  });
  // hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const AddOrDeleteUserFirebase = async () => {
    if (loggedInUser.userRoles === 'Admin') return history.push('/admin');

    const userQuery = query(
      collection(db, 'login'),
      where('data.email', '==', loggedInUser.email),
      orderBy('createdAt')
    );

    const User = await getDocs(userQuery);
    if (User.docs.length >= 1) {
      while (User.docs.length >= 1) {
        const useDoc = doc(db, 'login', User.docs[0]?.id);
        await deleteDoc(useDoc);
        toast({
          title: 'Không đăng nhập trên nhiều cửa sổ',
          description: 'Vui lòng đăng nhập lại',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top',
          containerStyle: {
            mt: '40px',
          },
        });
        return;
      }
    }

    AddDataCollection({
      nameCollection: 'login',
      parameters: {
        data: { ...loggedInUser },
        timeLogin: moment().format('MM/DD/YYYY hh:mm:ss'),
        status: 0,
        createdAt: serverTimestamp(),
      },
    }).then((res) => {
      getIdFirebase(res?.id);
      history.push('/user/room', {
        idFirebase: res?.id,
      });
      return;
    });
  };

  // check login status
  const loginStatus = async () => {
    if (message) {
      if (!loggedInUser) {
        toast({
          title: message,
          description: '',
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'top',
          containerStyle: {
            mt: '40px',
          },
        });
        resetMessage();
        return;
      } else {
        resetMessage();
        AddOrDeleteUserFirebase();
      }
    }
  };
  useEffect(() => {
    loginStatus();

    // disable button
    if (disableButton) {
      const timer = setTimeout(() => {
        setDisableButton(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [message, disableButton]);

  // email chưa xác thực => push validate form
  useEffect(() => {
    if (message === 'Email này chưa xác thực!') {
      setTimeout(() => {
        history.push('/verification', { userEmail: userEmail });
      }, 3000);
      resetMessage();
      return;
    }
  }, [message]);

  // send form
  const onsubmit = async (values: any) => {
    if (values) {
      setUserEmail(values.email.toLowerCase());
      const Password = cryptoJS.MD5(values.password).toString();
      const Email = values.email.toLowerCase();
      login(Email, Password);
      setDisableButton(true);
    }
  };
  // check user Roles
  useEffect(() => {
    if (loggedInUser && loggedInUser?.userRoles === 'Admin') {
      history.push('/admin');
      return;
    }
  }, [loggedInUser]);
  console.log('🚀 ~ loggedInUser:', loggedInUser);

  return (
    <>
      <CenteredAuth
        image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
        cardTop={{ base: '140px', md: '14vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
        mx="0px"
      >
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          justifyContent="center"
          px={{ base: '20px', md: '0px' }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="20px">
              Đăng nhập
            </Heading>
          </Box>

          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            {/* =====form=============== */}
            <form onSubmit={handleSubmit(onsubmit)} autoComplete="off">
              <FormControl>
                {/* =======email=============== */}
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  htmlFor="email"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  id="email"
                  {...register('email')}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="text"
                  placeholder="Nhập email"
                  fontWeight="500"
                  size="lg"
                />
                <Text fontSize={'xs'} mb="24px" mt="5px" color={'red'} ml="5px">
                  {errors?.email?.message}
                </Text>

                {/* ==========password================= */}
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  htmlFor="password"
                >
                  Mật khẩu<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    id="password"
                    {...register('password')}
                    fontSize="sm"
                    ms={{ base: '0px', md: '4px' }}
                    placeholder="Nhập mật khẩu"
                    size="lg"
                    type={showPassword ? 'text' : 'password'}
                    variant="auth"
                  />

                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputRightElement>
                </InputGroup>
                <Text fontSize={'xs'} mb="24px" mt="5px" color={'red'} ml="5px">
                  {errors?.password?.message}
                </Text>
                <Flex justifyContent="end" mb="24px">
                  <NavLink to="/forgotpassword">
                    <Text
                      color={textColorBrand}
                      fontSize="sm"
                      w="124px"
                      fontWeight="500"
                    >
                      Quên mật khẩu?
                    </Text>
                  </NavLink>
                </Flex>
                <Button
                  ms={{ base: '0px', md: '4px' }}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  isDisabled={disableButton ? true : false}
                  _hover={{ bg: brandStars }}
                >
                  Đăng nhập
                </Button>
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                  Chưa có tài khoản?
                  <NavLink to="/register">
                    <Text
                      color={textColorBrand}
                      as="span"
                      ms="5px"
                      fontWeight="500"
                    >
                      Đăng ký
                    </Text>
                  </NavLink>
                </Text>
              </Flex>
            </form>
          </Flex>
        </Flex>
      </CenteredAuth>
    </>
  );
}
