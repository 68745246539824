import React from "react";
import Card from "components/card/Card";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";

const MessagesBlock = ({ data }: any) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandBg = useColorModeValue("brand.500", "brand.400");
  return (
    <Card height="100%">
      <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold" mb="10px">
        Messages
      </Text>
      <Box overflow="auto">
        {data &&
          data?.docs?.map((doc: any) => {
            let tempTime = new Date(
              doc.data().createdAt.seconds * 1000
            ).toString();
            let createdAt = moment(tempTime).format("HH:mm:ss");
            return (
              <Flex
                justifyContent="center"
                alignItems="center"
                w="100%"
                px="12px"
                py="16px"
                borderRadius="16px"
              >
                <Flex
                  bg={brandBg}
                  w="4px"
                  h="100%"
                  borderRadius="16px"
                  me="10px"
                />
                <Flex direction="row" align="center" me="auto" gap={"20px"}>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    {createdAt}
                  </Text>
                  <Text
                    textAlign="start"
                    color={textColorPrimary}
                    fontSize="md"
                    me="6px"
                    fontWeight="700"
                  >
                    {doc.data().data}
                  </Text>
                </Flex>
              </Flex>
            );
          })}
      </Box>
    </Card>
  );
};

export default MessagesBlock;
