// Chakra imports
import {
  Flex,
  Text,
  Avatar,
  useColorModeValue,
  Box,
  AvatarBadge,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useAuthStore } from 'components/zustand/useAuthStore';
import ImageUploadModal from 'components/upload/ImageUploadModal';

export default function UserProfile() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const brandColor = useColorModeValue('brand.500', 'white');

  //zustand
  const { loggedInUser } = useAuthStore((state) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onpenModal = () => {
    onOpen();
  };

  return (
    <Card mb="20px">
      <Flex align="center">
        <Box>
          <Avatar
            src={loggedInUser.avatar}
            h="87px"
            w="87px"
            me="20px"
            bg={'gray.500'}
          >
            <AvatarBadge
              boxSize="1.4em"
              bg={brandColor}
              mr={'10px'}
              cursor={'pointer'}
              onClick={onpenModal}
            >
              <Text>+</Text>
            </AvatarBadge>
          </Avatar>
        </Box>
        <Flex direction="column">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
            {loggedInUser?.fullName}
          </Text>
          <Text mt="1px" color={textColorSecondary} fontSize="md">
            {loggedInUser?.email}
          </Text>
        </Flex>
      </Flex>
      {/* ==========image modal============== */}
      <ImageUploadModal isOpen={isOpen} onClose={onClose} />
    </Card>
  );
}
