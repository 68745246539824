import { Grid, GridItem, useToast } from "@chakra-ui/react";
import { useAuthStore } from "components/zustand/useAuthStore";
import { db } from "configs/firebase";
import { collection, doc, limit, orderBy, query } from "firebase/firestore";
import _ from "lodash";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useHistory, useParams } from "react-router-dom";
import MessagesBlock from "./components/MessagesBox/index";
import SlideQuiz from "./components/SlideQuiz";
import StudentList from "./components/StudentList";
import TopUserScore from "./components/TopUserScore/index";

type TypeParams = {
  questionId: string;
  groupId: string;
};

const OlympicViewer = ({ rest }: any) => {
  const { questionId, groupId }: TypeParams = useParams();
  const [dataQuestion, setDataQuestion] = React.useState<any>(undefined);
  const [dataUsers, setDataUsers] = React.useState<any>(undefined);
  const [dataReports, setDataReports] = React.useState<any>(undefined);
  const [showQuestion, setShowQuestion] = React.useState<boolean>(false);
  const [isEndQuestion, setIsEndQuestion] = React.useState<boolean>(false);
  const [isEndCountdown, setIsEndCountdown] = React.useState<boolean>(false);

  const { loggedInUser } = useAuthStore((state) => state);

  const isPass = _.filter(dataUsers, { studentId: loggedInUser.id })?.[0]
    ?.isPass;

  const history = useHistory();
  const collectionRef = doc(db, "Group", `Group-${groupId}`);
  const usersRef = query(
    collection(collectionRef, "users"),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  const reportRef = query(
    collection(collectionRef, "reports"),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const statusGroupRef = query(
    collection(collectionRef, "status"),
    orderBy("createdAt", "desc")
  );
  const questionRef = query(
    collection(collectionRef, "questions"),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  const lastMessageQueryRef = query(
    collection(collectionRef, "message"),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  const allMessageQueryRef = query(
    collection(collectionRef, "message"),
    orderBy("createdAt", "desc")
  );

  const toastMessage = useToast();

  const [lastMessage] = useCollection(lastMessageQueryRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [allMessage] = useCollection(allMessageQueryRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [question, loadingQuestion] = useCollection(questionRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [users] = useCollection(usersRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [reports] = useCollection(reportRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [statusGroup] = useCollection(statusGroupRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  React.useEffect(() => {
    question?.docChanges({ includeMetadataChanges: true }).forEach((change) => {
      if (change.type === "added" || change.type === "modified") {
        setDataQuestion(change.doc.data());
        setIsEndQuestion(change.doc.data().isEndQuestion);
        setIsEndCountdown(change.doc.data().isEndCountdown);
        setShowQuestion(true);
      }
    });
    return () => setDataQuestion([]);
  }, [question]);

  React.useEffect(() => {
    statusGroup
      ?.docChanges({ includeMetadataChanges: true })
      .forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          if (change.doc.data().data === 1) {
            history.push("/login");
          }
        }
      });
  }, [statusGroup]);

  React.useEffect(() => {
    lastMessage
      ?.docChanges({ includeMetadataChanges: true })
      .forEach((change) => {
        if (change.type === "added") {
          const { data, typeMessage } = change.doc.data();
          toastMessage.closeAll();
          toastMessage({
            title: "Thông báo từ quản trị viên",
            description: data,
            status: typeMessage,
            duration: 2000,
            isClosable: true,
            position: "top",
            onCloseComplete() {
              if (change?.doc?.data()?.status === 1)
                return history.push("/login");
            },
          });
        }
      });
  }, [lastMessage]);

  React.useEffect(() => {
    users?.docChanges({ includeMetadataChanges: true }).forEach((change) => {
      if (change.type === "added" || change.type === "modified") {
        setDataUsers(change.doc.data().data);
      }
    });
  }, [users]);

  React.useEffect(() => {
    reports?.docChanges({ includeMetadataChanges: true }).forEach((change) => {
      if (change.type === "added" || change.type === "modified") {
        setDataReports(change.doc.data().data);
      }
    });
  }, [reports]);

  return (
    <Grid
      templateColumns={"repeat(12,1fr)"}
      templateRows={"repeat(12,1fr)"}
      gap={3}
      pt={{ base: "140px", md: "80px", xl: "80px" }}
      px={"20px"}
      height={{ base: "93vh", sm: "auto" }}
    >
      <GridItem colSpan={{ sm: 12, lg: 7 }} rowSpan={{ lg: 5, sm: 3 }}>
        <StudentList dataUsers={dataUsers} title="Danh sách thí sinh" />
      </GridItem>
      <GridItem colSpan={{ sm: 12, lg: 5 }} rowSpan={{ lg: 6, sm: 4 }}>
        <TopUserScore data={dataReports} />
      </GridItem>
      <GridItem colSpan={{ sm: 12, lg: 7 }} rowSpan={8}>
        {showQuestion && (
          <SlideQuiz
            data={[dataQuestion]}
            currentQuizIndex={0}
            loggedInUser={loggedInUser}
            loading={loadingQuestion}
            isPass={isPass}
            isEndCountdown={isEndCountdown}
            isEndQuestion={isEndQuestion}
          />
        )}
      </GridItem>
      <GridItem colSpan={{ sm: 12, lg: 5 }} rowSpan={6}>
        <MessagesBlock data={allMessage} />
      </GridItem>
    </Grid>
  );
};

export default OlympicViewer;
