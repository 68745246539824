import { ChakraProvider } from '@chakra-ui/react';
import { persistor, store } from 'constants/store';
import UserLayout from 'layouts/user';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import QuestionControllerDashboard from 'views/admin/dashboards/questionController';
import SignIn from 'views/auth/signIn/signTest';
import Login from 'views/Login';
import Register from 'views/register';
import Verification from 'views/verification';
import './assets/css/App.css';
import AdminLayout from './layouts/admin';
import AuthLayout from './layouts/auth';
import RTLLayout from './layouts/rtl';
import theme from './theme/theme';
import UserForgotPassword from 'components/form/user/UserForgotPassword';
import IndexAdminProfile from 'views/admin/profile/IndexAdminProfile';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
              {/* <Route path={`/login`} component={SignIn} /> */}
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />

              <Route path={`/rtl`} component={RTLLayout} />
              <Route path={`/user`} component={UserLayout} />
              <Route path={`/register`} component={Register} />
              <Route path={`/verification`} component={Verification} />
              <Route path={`/login`} component={Login} />
              <Route path={`/forgotpassword`} component={UserForgotPassword} />
              <Redirect from="/" to="/login" />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);
