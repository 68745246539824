import { Flex, List, ListIcon, ListItem, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { query } from 'helper/QueryHelper'
import React from 'react'
import { BsMailbox } from 'react-icons/bs'
import { IoMailOpen } from 'react-icons/io5'

type Props = {
  id: number | string
}

const ModalViewUser = ({ id }: Props) => {
  const [dataUserInGroup, setDataUserInGroup] = React.useState<any>()
  const selectListStudentById = () => {
    query('p_Examinations_Groups_GetUser', {
      groupId: id,
    }).then((response: any) => {
      setDataUserInGroup(response)
    })
  }
  React.useEffect(() => {
    selectListStudentById()
  }, [id])
  return (
    <div>
      <TableContainer>
        <Table variant='simple' size={'sm'} mt='12px'>
          <Thead>
            <Tr>
              <Th>STT</Th>
              <Th>Họ Tên</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
              <Th isNumeric>Cấp</Th>
            </Tr>
          </Thead>
          <Tbody margin={'20px 0'}>
            {dataUserInGroup?.map((item: any, index: number) => (
              <Tr marginTop={10}>
                <Td>{index + 1}</Td>
                <Td>{item.fullName}</Td>
                <Td>{item.email}</Td>
                <Td>{item.phone}</Td>
                <Td isNumeric>{item.grade}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ModalViewUser
