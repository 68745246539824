import { Badge, Center, Flex, GridItem, Heading, Text } from "@chakra-ui/react";
import moment from "moment";
import Card from "components/card/Card";

const TopUserCard = ({ headingText, data, color }: any) => {
  return (
    <GridItem colSpan={{ lg: 1, md: 2, sm: 1 }}>
      <Card height="100%">
        <Text fontSize="2xl" color={color} fontWeight="bold" mb="10px">
          {headingText}
        </Text>
        {data.map((item: any, index: number) => (
          <Flex gap={3} mb="10px">
            <Center
              w="30px"
              h="30px"
              bg={color}
              color="white"
              borderRadius="50%"
            >
              {index + 1}
            </Center>
            <Center flex="1" justifyContent={"space-between"}>
              <Text fontWeight={500}>{item.userName}</Text>
              <Center gap="0 10px">
                <Badge colorScheme="green">{item.score}</Badge>
                <Badge colorScheme="purple">
                  {moment.utc(item.duration * 1000).format("mm:ss")}
                </Badge>
              </Center>
            </Center>
          </Flex>
        ))}
      </Card>
    </GridItem>
  );
};

export default TopUserCard;
