import { Box, Grid, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import colors from "constants/colors";
import _ from "lodash";
import React from "react";
import { AiOutlineSound as SpeakerIcon } from "react-icons/ai";

import { Button } from "@chakra-ui/react";
import { query } from "helper/QueryHelper";
import moment from "moment";
import { useDispatch } from "react-redux";
import AudioButton from "../../AudioButton";
import { QuestionComponentProps } from "../type";
import "./styles.css";

const alphabet = "abcdefghijklmnopqrstxyuvz";
const number = "123456789";

function renderUnderline(item: any, data: any) {
  const underlined = data?.settings?.answers?.underlined ?? false;
  if (underlined) {
    const head = item.slice(0, item.length - 2);
    const tail = item.slice(head.length);
    item = `${head}<${tail}>`;
  }

  let flag = false;
  return item?.split("")?.map((character: any, index: number) => {
    if (character === "<") {
      flag = true;
    }
    if (character === ">") {
      flag = false;
    }
    // Nếu là '<' hoặc '>' thì bỏ qua
    if (character === "<" && flag) return "";
    if (character === ">" && !flag) return "";

    return (
      <React.Fragment key={index}>
        {flag ? (
          <span style={{ textDecoration: "underline" }}>{character}</span>
        ) : (
          character
        )}
      </React.Fragment>
    );
  });
}

function OneChoice({
  data,
  onSubmit,
  userData,
  preview = false,
  showUserData = false,
  shuffed = false,
  index,
  isEndCountdown = false,
  isEndQuestion = false,
}: QuestionComponentProps) {
  const [selectedAnswer, setSelectedAnswer] = React.useState<any>(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  // Âm thanh
  const refAudio = React.useRef<any>();
  refAudio.current = new Audio();

  // SHUFFED ANSWERS
  const [shuffedAnswers, setShuffedAnswers] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (userData && showUserData) {
      const array = userData.answers;
      setShuffedAnswers(array);

      const selected = _.find(array, (x) => x.selected);
      setSelectedAnswer(selected);
    } else {
      const answers = data?.answers?.map((item: any, index: number) => {
        return { ...item, id: index + 1 };
      });
      const array = preview ? answers : _.shuffle(answers);
      setShuffedAnswers(array);
    }

    // Chế độ xem thử
    if (preview) {
      const correctAnswer = _.find(data.answers, (answer) => answer.isCorrect);
      setSelectedAnswer(correctAnswer);
    }
  }, [data, preview, userData]);

  // RENDER FUNCTIONS
  // RENDER QuestionText
  const renderQuestionText = () => {
    // if (data.questionText === null || data.questionText === undefined || data.questionText === '') {
    //   return <React.Fragment />;
    // }
    if (data?.questionText || data?.questionSub) {
      return (
        <Box>
          <Text
            color={textColor}
            fontSize="md"
            me="6px"
            fontWeight="700"
            mb={{ base: "10px", md: "0px" }}
            textColor={textColor}
          >
            {index ? index + "." : ""} {data.questionText || data.questionSub}
          </Text>
        </Box>
      );
    }
    return "";
  };

  // RENDER IMAGE
  const renderQuestionImage = () => {
    if (
      data.imageUrl === null ||
      data.imageUrl === undefined ||
      data.imageUrl === ""
    ) {
      return <React.Fragment />;
    }
    return (
      <div className="mb-4">
        <img alt="" src={data.imageUrl} style={{ height: 200 }} />
      </div>
    );
  };

  // RENDER Question
  const renderQuestion = () => {
    let renderResult = <React.Fragment />;
    if (
      data.question === null ||
      data.question === undefined ||
      data.question === ""
    ) {
      return renderResult;
    }
    // tìm kiếm '___' và chèn khoảng trắng, replace 1 lần nữa để tìm nếu dư khoảng trắng trong chuỗi.
    data.question = data.question.replace(/___/g, " ___ ").replace(/\s+/g, " ");
    data.question = _.replace(data.question, ".......", "___");
    if (data.question.includes("___")) {
      const words = data.question;
      // renderResult = words.replace(/___/g, ' ___ ').replace(/\s+/g, ' ');
      renderResult = words?.split(" ")?.map((word: string, index: number) => {
        const flag = word === "___,";
        return (
          <React.Fragment key={index}>
            <Text
              as="span"
              whiteSpace="nowrap"
              fontSize="lg"
              fontWeight="600"
              textColor={textColor}
            >
              {word === "___" || flag ? (
                selectedAnswer !== null &&
                selectedAnswer !== undefined &&
                selectedAnswer !== null ? (
                  <span
                    style={{
                      color: colors.theme.success,
                      paddingBottom: 3,
                      borderBottom: "1px dashed #adb5bd",
                    }}
                  >
                    {" "}
                    {selectedAnswer?.answer}
                  </span>
                ) : (
                  <span
                    style={{
                      paddingLeft: 120,
                      paddingBottom: 3,
                      borderBottom: "1px dashed #adb5bd",
                    }}
                  />
                )
              ) : (
                word
              )}
              {flag && ", "}
              &nbsp;
            </Text>
          </React.Fragment>
        );
      });

      return (
        <div className="questionText" style={{ padding: "20px 0" }}>
          {renderResult}
        </div>
      );
    }

    // FOCUSED TEXT
    if (data.focusedWords) {
      const words = data.question;
      renderResult = words?.split(" ")?.map((word: string, index: number) => {
        const found = _.find(data.focusedWords, (x) => {
          const a = x.replace(".", "");
          const b = word.replace(".", "");
          return a === b;
        });

        if (found) {
          return (
            <span key={`word-${index}`}>
              {word?.split(found)?.map((item, i) => {
                return (
                  <React.Fragment key={`part-${i}`}>
                    {i !== 0 && (
                      <Text
                        fontSize="lg"
                        me="6px"
                        fontWeight="700"
                        mb={{ base: "10px", md: "0px" }}
                        textColor={textColor}
                      >
                        {found}
                      </Text>
                    )}
                    {item}
                  </React.Fragment>
                );
              })}
            </span>
          );
        }
        return (
          <Text
            key={`word-${index}`}
            fontSize="lg"
            me="6px"
            fontWeight="700"
            mb={{ base: "10px", md: "0px" }}
            textColor={textColor}
          >
            {word}&nbsp;
          </Text>
        );
      });
    } else {
      renderResult = (
        <Text
          fontSize="lg"
          me="6px"
          fontWeight="700"
          mb={{ base: "10px", md: "0px" }}
          textColor={textColor}
        >
          {data.question}
        </Text>
      );
    }

    return <div className="questionText">{renderResult}</div>;
  };

  const renderImageAnswers = () => {
    let direction =
      data?.settings &&
      data?.settings?.answers &&
      data?.settings?.answers?.direction;
    if (direction === null || direction === undefined) {
      direction = "vertical";
    }

    let bulletType =
      data?.settings &&
      data?.settings?.answers &&
      data?.settings?.answers?.bulletType;
    if (bulletType === null || bulletType === undefined) {
      bulletType = "character";
    }

    return (
      <div className="answers-onechoice answers-onechoice-horizontal">
        {shuffed
          ? shuffedAnswers
          : data?.answers?.map((item: any, index: number) => {
              const isSelected =
                selectedAnswer &&
                selectedAnswer.id === item.id &&
                selectedAnswer.answer === item.answer;

              return (
                <div
                  key={`answer-${index}`}
                  className="tw-p-4 tw-mr-4"
                  style={{
                    cursor: "pointer",
                    borderRadius: 8,
                    border: isSelected
                      ? "2px solid #2dce89"
                      : "2px dashed #adb5bd",
                  }}
                >
                  <img
                    alt=""
                    src={item.answer}
                    style={{ height: 220 }}
                    onClick={() => {
                      setSelectedAnswer(item);
                    }}
                  />
                </div>
              );
            })}
      </div>
    );
  };

  const renderAnswers = () => {
    let direction =
      data?.settings &&
      data?.settings?.answers &&
      data?.settings.answers.direction;
    if (direction === null || direction === undefined) {
      direction = "vertical";
    }

    let bulletType =
      data?.settings &&
      data?.settings?.answers &&
      data?.settings.answers.bulletType;
    if (bulletType === null || bulletType === undefined) {
      bulletType = "character";
    }

    return (
      <Grid templateColumns="repeat(1, 1fr)" gap="10px" pt="20px">
        {shuffed
          ? shuffedAnswers
          : data?.answers?.map((item: any, index: number) => {
              const correctAnswer = _.filter(data?.answers, {
                isCorrect: true,
              })?.[0];
              const isSelected =
                selectedAnswer && selectedAnswer?.answer === item.answer;
              const isCorrect =
                selectedAnswer?.answer === correctAnswer?.answer;

              const setColor = () => {
                if (isSelected) {
                  if (isCorrect) {
                    return "#1DD1A1";
                  } else {
                    if (isEndQuestion) {
                      return "#ff6b6b";
                    } else {
                      return "#48dbfb";
                    }
                  }
                } else {
                  return "#FFFFFF";
                }
              };

              return (
                <Button
                  key={`answer-${index}`}
                  variant="setup"
                  px="24px"
                  fontSize="md"
                  fontWeight="500"
                  size="md"
                  height="48px"
                  borderColor="#ccd0d5"
                  backgroundColor={setColor()}
                  _hover={{ bg: "#48dbfb" }}
                  _active={{
                    transform: "scale(0.98)",
                    borderColor: "#bec3c9",
                  }}
                  _focus={{
                    boxShadow:
                      "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                  }}
                  onClick={async () => {
                    setSelectedAnswer(item);
                    if (item.audioUrl) {
                      refAudio.current.pause();
                      refAudio.current.currentTime = 0;
                      refAudio.current.src = item.audioUrl;
                      refAudio.current.play();
                    }

                    if (!isEndCountdown) {
                      handleSubmit(item, parseInt(moment().format("X")));
                    }
                  }}
                  color={isSelected ? "success" : "secondary"}
                  // isDisabled={
                  //   isDisableButton
                  //     ? selectedAnswer
                  //       ? !isSelected
                  //       : false
                  //     : true
                  // }
                  isDisabled={isEndCountdown}
                >
                  {bulletType === "character" &&
                    `${alphabet[index].toUpperCase()}. `}
                  {bulletType === "number" &&
                    `${number[index].toUpperCase()}. `}
                  {bulletType === "special characters" ? (
                    <React.Fragment>
                      {item.answer.trim() === "up" ? "↗" : "↘"}
                    </React.Fragment>
                  ) : (
                    renderUnderline(item.answer, data)
                  )}
                  {item.audioUrl && (
                    <SpeakerIcon
                      size={24}
                      style={{ marginTop: -4, verticalAlign: "middle" }}
                    />
                  )}
                </Button>
              );
            })}
      </Grid>
    );
  };

  const getResult = async (selectedAnswer: any) => {
    const res = await query("p_Question_Answer_Check", {
      QuestionId: data?.rowId,
      Answer: selectedAnswer?.answer,
    });
    return Object.values(res?.[0])?.[0];
  };

  // Handle Submit Button
  const handleSubmit = async (selectedAnswer: any, time: number) => {
    const result = {
      time: time,
      answer: selectedAnswer.answer,
      isCorrect: await getResult(selectedAnswer),
    };

    // Callback
    const answers = [...shuffedAnswers];
    answers.forEach((x) => {
      if (x.id === selectedAnswer?.id) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });

    if (onSubmit && typeof onSubmit === "function")
      onSubmit({ answers, result: result });
  };

  return (
    <Box>
      {/* Question */}
      <AudioButton audioUrl={data.audioUrl} />
      {renderQuestionText()}
      {renderQuestionImage()}
      {renderQuestion()}
      {/* Answers */}
      {data?.settings?.answers?.imageAnswers
        ? renderImageAnswers()
        : renderAnswers()}
      {/* <CardFooter>
        <Button
          color="primary"
          disabled={!selectedAnswer}
          onClick={handleSubmit}
        >
          Nộp bài <i className="ml-3 fas fa-arrow-right" />
        </Button>
      </CardFooter> */}
    </Box>
  );
}

export default OneChoice;
