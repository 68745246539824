import { Grid } from "@chakra-ui/react";
import _ from "lodash";
import TopUserCard from "./TopUserCard/index";

const TopUserScore = ({ data }: any) => {
  const filterStudentStatus = (
    items: any,
    maxCount: number,
    status: boolean
  ) => {
    var results: any[] = [];
    _.forEach(items, function (item) {
      if (results.length === maxCount) {
        return false;
      }
      if (item.isPass === status) {
        results.push(item);
      }
    });
    return results;
  };
  return (
    <Grid
      overflow="hidden"
      scrollBehavior="auto"
      templateColumns="repeat(2, 1fr)"
      gap={"20px"}
      height="100%"
      width="100%"
    >
      <TopUserCard
        headingText="Top thí sinh"
        data={filterStudentStatus(data, 5, true)}
        color="#1dd1a1"
      />
      <TopUserCard
        headingText="Top thí sinh bị loại"
        data={filterStudentStatus(data, 5, false)}
        color="#ff6b6b"
      />
    </Grid>
  );
};

export default TopUserScore;
