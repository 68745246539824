export const OLYMPIC_START = "OLYMPIC_START";
export const OLYMPIC_UPDATE_COUNTDOWN = "OLYMPIC_UPDATE_COUNTDOWN";

export const OLYMPIC_SUBMIT = "OLYMPIC_SUBMIT";
export const OLYMPIC_SUBMIT_SUCCESS = "OLYMPIC_SUBMIT_SUCCESS";
export const OLYMPIC_SUBMIT_FAILURE = "OLYMPIC_SUBMIT_FAILURE";

export const OLYMPIC_SUBMIT_ALL = "OLYMPIC_SUBMIT_ALL";
export const OLYMPIC_SUBMIT_ALL_SUCCESS = "OLYMPIC_SUBMIT_ALL_SUCCESS";
export const OLYMPIC_SUBMIT_ALL_FAILURE = "OLYMPIC_SUBMIT_ALL_FAILURE";
