import { CheckIcon, CloseIcon, Icon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useAuthStore } from "components/zustand/useAuthStore";
import { BsFlagFill } from "react-icons/bs";

export default function StudentList({ dataUsers, title }: any) {
  const { loggedInUser } = useAuthStore((state) => state);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card height="100%">
      <Flex mb="40px" justify="space-between" align="center">
        <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
          {title}
        </Text>
      </Flex>
      <SimpleGrid
        columns={{ sm: 12, md: 17, lg: 14, "2xl": 20 }}
        gap={2}
        textAlign="center"
      >
        {dataUsers?.map((user: any) => {
          return (
            <Flex
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              gap="5px 0"
            >
              {user.email === loggedInUser.email && (
                <Icon as={BsFlagFill} color={"#190793"}></Icon>
              )}
              {user.isPass ? (
                <CheckIcon
                  w={"30px"}
                  h={"30px"}
                  bgColor={"#1dd1a1"}
                  borderRadius="full"
                  color={"white"}
                  p="5px"
                />
              ) : (
                <CloseIcon
                  w={"30px"}
                  h={"30px"}
                  bgColor={"#ff6b6b"}
                  borderRadius="full"
                  color={"white"}
                  p="6px"
                />
              )}
            </Flex>
          );
        })}
      </SimpleGrid>
    </Card>
  );
}
