import * as ActionTypes from "./types";

export const start = (examinationId) => {
  return {
    type: ActionTypes.OLYMPIC_START,
    payload: examinationId,
  };
};

export const updateCountDown = (value) => {
  return {
    type: ActionTypes.OLYMPIC_UPDATE_COUNTDOWN,
    payload: value,
  };
};

export const submit = (question) => {
  return {
    type: ActionTypes.OLYMPIC_SUBMIT,
    payload: question,
  };
};

export const submitAll = (questions) => {
  return {
    type: ActionTypes.OLYMPIC_SUBMIT_ALL,
    payload: questions,
  };
};
