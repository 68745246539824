// Chakra imports
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
// Assets
import AccountSettings from './AccountSettings';
import UserProfile from './UserProfile';
import ChangePassword from './ChangePassword';

export default function IndexProfile() {
  return (
    <Flex
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      w={'100%'}
      justifyContent={'center'}
    >
      <Grid
        mb="20px"
        templateColumns="repeat(12,1fr)"
        w={'90%'}
        // columns={{ sm: 1, lg: 2 }}
        // spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Column Left */}
        <GridItem colSpan={12}>
          <UserProfile />
        </GridItem>
        <GridItem colSpan={{ lg: 6, sm: 12 }} pr={{ md: '0', lg: '20px' }}>
          <AccountSettings />
        </GridItem>
        <GridItem colSpan={{ lg: 6, sm: 12 }} mt={{ sm: '20px', lg: '0' }}>
          <ChangePassword />
        </GridItem>
        <Flex direction="column"></Flex>
        {/* Column Right */}
        <Flex direction="column">{/* <Socials /> */}</Flex>
      </Grid>
    </Flex>
  );
}
