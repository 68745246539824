import {
  Box,
  Flex,
  Heading,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";

export default function QuestionHeader({
  title,
  subTitle,
  questionIndex,
}: any) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  return (
    <Flex gap="0 20px" mb="20px">
      <Tooltip>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderColor={borderColor}
          textColor="white"
          backgroundColor={textColorPrimary}
          rounded="50%"
          style={{ height: 48, width: 48, fontWeight: 600 }}
        >
          {questionIndex}
        </Box>
      </Tooltip>
      <Box flex={1} textColor={textColorPrimary}>
        <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
          {title}
        </Text>
        <Text pt="2" fontSize="sm">
          {subTitle}
        </Text>
      </Box>
    </Flex>
  );
}
