import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Heading,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  updateDoc,
} from "@firebase/firestore";
import { useAuthStore } from "components/zustand/useAuthStore";
import { db } from "configs/firebase";
import _ from "lodash";
import { DocDocument, FirstDocDocument } from "modules/firebase/docDocument";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useHistory, useParams } from "react-router-dom";

const IndexWaitingGroup = (props: any) => {
  const history = useHistory();
  const { idFirebase } = props.location.state;
  const params: { groupId: string } = useParams();
  const collectionRef = doc(db, "Group", `Group-${params.groupId}`);
  const questionRef = query(
    collection(collectionRef, "questions"),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const { login, loggedInUser, loading, message, resetMessage } = useAuthStore(
    (state: any) => state
  );
  const toastMessage = useToast();
  const loginRef = collection(db, "login");
  let loginDocRef = doc(loginRef, idFirebase);
  const [dataUserInGroup, setDataUserInGroup] = React.useState<any>([]);
  const { data: users } = FirstDocDocument({
    idDocument: `Group-${params.groupId}`,
    nameDocument: "Group",
    nameCollection: `users`,
  });

  React.useEffect(() => {
    users?.docChanges({ includeMetadataChanges: true }).forEach((change) => {
      if (change.type === "added" || change.type === "modified") {
        setDataUserInGroup(change.doc.data().data);
      }
    });
  }, [users]);

  const statusGroupRef = query(
    collection(collectionRef, "status"),
    orderBy("createdAt", "desc")
  );
  const [statusGroup] = useCollection(statusGroupRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  React.useEffect(() => {
    statusGroup
      ?.docChanges({ includeMetadataChanges: true })
      .forEach((change) => {
        if (
          change.type === "added" ||
          change.type === "modified" ||
          change.type === "removed"
        ) {
          if (change?.doc?.data()?.data !== 0) {
            toastMessage({
              title: "Thông báo từ hệ thống",
              description: "Cuộc thi đã kết thúc.",
              status: "info",
              duration: 3000,
              isClosable: true,
              position: "top",
              id: change?.doc?.data()?.data.studentId,
              onCloseComplete() {
                if (change?.doc?.data()?.data !== 0)
                  return history.push("/login");
              },
            });
          }
        }
      });
  }, []);
  React.useEffect(() => {
    updateDoc(loginDocRef, {
      status: 5,
    });
  }, []);

  const [question] = useCollection(questionRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  React.useEffect(() => {
    question?.docChanges({ includeMetadataChanges: true }).forEach((change) => {
      if (change.type === "added" || change.type === "modified") {
        let questionId = change.doc.data().data.rowId;
        history.push(`/user/group/${params.groupId}/question/${questionId}`);
      }
    });
  }, [question]);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} px={"20px"}>
      <Center>
        <Heading fontSize="xl" textTransform={"uppercase"}>
          Phòng chờ thi nhóm {params.groupId}
        </Heading>
      </Center>
      <Wrap padding={"0 20px"}>
        {dataUserInGroup?.map((item: any, index: number) => {
          return (
            <WrapItem padding="20px 0" key={index}>
              <Card
                border={`2px solid ${
                  item?.email === loggedInUser?.email ? " #62CDFF" : "#68D391"
                }`}
              >
                <Center>
                  <CardHeader>
                    <Flex>
                      <Flex
                        flex="1"
                        gap="4"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Avatar
                          name={item?.studentName}
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1JTS3RJUI4wkYE9x7QNC18tQk4OlwdTKaq8Uchw4n6w&s"
                        />
                        <Box>
                          <Heading size="sm">{item?.studentName}</Heading>
                          <Text>{item?.email}</Text>
                        </Box>
                      </Flex>
                    </Flex>
                  </CardHeader>
                </Center>
              </Card>
            </WrapItem>
          );
        })}
      </Wrap>
    </Box>
  );
};

export default IndexWaitingGroup;
