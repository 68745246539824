// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD38FaC8uJp157-SAp6N9T7Ig_V_p1w4LM',
  authDomain: 'olympic-english.firebaseapp.com',
  projectId: 'olympic-english',
  storageBucket: 'olympic-english.appspot.com',
  messagingSenderId: '545305027966',
  appId: '1:545305027966:web:ad670bf0835ebb09dd3f2f',
};

// Initialize Firebase
const app = getApps().length ? getApp() : initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export { db, auth, provider };
