import {
  Box,
  Button,
  Card,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { query } from "helper/QueryHelper";
import React from "react";
import ModalViewUser from "./components/ModalViewUser";
import * as QueryHelper from "helper/QueryHelper";
import _ from "lodash";
import Confirm from "modules/confirm";
import { useHistory } from "react-router-dom";
import {
  AddDataCollection,
  AddDataDocument,
} from "modules/firebase/docDocument";
import {
  collection,
  query as QueryFirestore,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "configs/firebase";

const columnHelper = createColumnHelper<any>();
const IndexInformationGroup = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataTable, setDataTable] = React.useState<any>([]);
  const [groupId, setGroupId] = React.useState<number | string>(undefined);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [visibleConfirm, setVisibleConfirm] = React.useState<boolean>(false);
  const [visibleConfirmEndTest, setVisibleConfirmEndTest] =
    React.useState<boolean>(false);
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const textColor = useColorModeValue("navy.700", "white");

  const history = useHistory();

  const selectDataTable = () => {
    query("t_Examinations_Groups_Get", {
      Grade: 0,
      RoundId: 0,
    }).then((response) => {
      setDataTable(response);
    });
  };
  React.useEffect(() => {
    selectDataTable();
  }, []);

  const handleEndTest = () => {
    if (groupId) {
      query("p_Examinations_Message_InsertUpdate", {
        Id: null,
        GroupId: groupId,
        Title: "Thông báo từ quản trị viên",
        Body: "Kết thúc thi",
        Icon: null,
        Color: null,
        Type: "info",
        CreatedBy: 1,
      }).then(async () => {
        query("p_Examinations_Groups_Delete", {
          GroupId: groupId,
        }).then(() => {
          const messageToStudentRef = QueryFirestore(
            collection(db, "messageToStudent"),
            where("groupId", "==", groupId)
          );
          getDocs(messageToStudentRef).then((res) => {
            res.docs?.forEach((doc) => {
              deleteDoc(doc.ref);
            });
          });
          AddDataDocument({
            nameDocument: "Group",
            idDocument: `Group-${groupId}`,
            nameCollection: "message",
            parameters: {
              data: "Kết thúc thi",
              typeMessage: "info",
              status: 1,
            },
          });
        });
      });
      AddDataDocument({
        nameDocument: "Group",
        idDocument: `Group-${groupId}`,
        nameCollection: "status",
        parameters: {
          data: 1,
        },
      });
      setVisibleConfirmEndTest(false);
    }
  };

  const handleRemoveGroup = () => {
    query("p_Examinations_Groups_GetUser", {
      groupId: groupId,
    }).then((response: any) => {
      response?.forEach((e: any) => {
        AddDataCollection({
          nameCollection: "messageToStudent",
          parameters: {
            data: `Cuộc thi đã kết thúc. Bạn sẽ được chuyển về trang Đăng nhập.`,
            toStudent: e.email,
            type: "remove",
          },
        });
      });
      QueryHelper.query("p_Examinations_Groups_Delete", {
        GroupId: groupId,
      }).then(() => {
        AddDataDocument({
          nameDocument: "Group",
          idDocument: `Group-${groupId}`,
          nameCollection: "status",
          parameters: {
            data: 2,
          },
        });
        // DeleteCollection({ nameCollection: 'messageToStudent' })

        _.remove(dataTable, {
          id: groupId,
        });
        setDataTable([...dataTable]);
        // DeleteCollection({ nameCollection: 'messageToStudent' })
        setVisibleConfirm(false);
      });
    });
  };

  const handleStartTest = (groupId: number) => {
    query("p_Examinations_ListStudent_Top", {
      ExamId: "7121A5FC-711F-4584-AB7C-AF3E3CCC8B29",
      ExamGroupId: groupId,
    }).then(async (res) => {
      AddDataDocument({
        nameDocument: "Group",
        idDocument: `Group-${groupId}`,
        nameCollection: "reports",
        parameters: {
          data: res,
        },
      }).then(() => {
        history.push(`/admin/dashboard/groups/${groupId}/question-controller`);
      });
    });
  };

  //
  const columns = [
    columnHelper.accessor("groupNo", {
      id: "groupNo",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          STT
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Flex direction="column">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Tên Nhóm
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center" justifyContent="center" width={"30%"}>
          <Flex direction="column">
            <Text
              color={textColor}
              textAlign="center"
              fontSize="sm"
              fontWeight="700"
            >
              Nhóm {info.getValue()}
            </Text>
          </Flex>
        </Flex>
      ),
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => <></>,
      cell: (info: any) => {
        return (
          <Flex align="center" justifyContent="end">
            <Flex direction="row" gap={5}>
              <Button
                onClick={() => {
                  handleStartTest(info.getValue());
                }}
                borderRadius="16px"
                ms="auto"
                colorScheme="blue"
              >
                Vào phòng thi
              </Button>
              <Button
                onClick={() => {
                  setVisibleConfirmEndTest(true);
                  setGroupId(info.getValue());
                }}
                colorScheme="red"
              >
                Kết thúc thi
              </Button>
              <Button
                onClick={() => {
                  setGroupId(info.getValue());
                  onOpen();
                }}
                colorScheme="green"
                borderRadius="16px"
                ms="auto"
              >
                Danh sách thi
              </Button>

              <Button
                onClick={() => {
                  setVisibleConfirm(true);
                  setGroupId(info.getValue());
                }}
                colorScheme="red"
                variant="outline"
                borderRadius="16px"
                ms="auto"
              >
                Xóa
              </Button>
            </Flex>
          </Flex>
        );
      },
    }),
  ];
  const table = useReactTable({
    data: dataTable,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <div>
      <Flex pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex direction="column" width="stretch">
          <Card padding={"20px 0"}>
            <Flex
              direction="column"
              w="100%"
              overflowX={{ sm: "scroll", lg: "hidden" }}
            >
              <Flex
                align={{ lg: "center" }}
                justify={{ base: "space-between" }}
                w="100%"
                px="20px"
                mb="20px"
              >
                <Flex direction="column">
                  <Text
                    color={textColor}
                    fontSize="xl"
                    fontWeight="600"
                    lineHeight="100%"
                  >
                    DANH SÁCH NHÓM THI
                  </Text>
                  <br />
                  <Text
                    color={textColor}
                    fontSize="md"
                    fontWeight="500"
                    lineHeight="100%"
                  >
                    {/* Số lượng: student1@gmail.com */}
                  </Text>
                </Flex>
                {/* <Flex>
                <Button onClick={handleRemoveListReady} color='red.500' variant='outline'>
                  Xóa danh sách thí sinh
                </Button>
                <Button onClick={onOpen}>Phân bổ nhóm</Button>
              </Flex> */}
              </Flex>
              <Box>
                <Table
                  variant="striped"
                  colorScheme="gray"
                  color="gray.500"
                  mt="12px"
                >
                  <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <Th
                              key={header.id}
                              colSpan={header.colSpan}
                              pe="10px"
                              borderColor={borderColor}
                              cursor="pointer"
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <Flex
                                justifyContent="space-between"
                                align="center"
                                fontSize={{ sm: "10px", lg: "12px" }}
                                color="gray.400"
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: "",
                                  desc: "",
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </Flex>
                            </Th>
                          );
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table
                      .getRowModel()
                      .rows.slice(0, 11)
                      .map((row) => {
                        return (
                          <Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <Td
                                  key={cell.id}
                                  fontSize={{ sm: "14px" }}
                                  minW={{
                                    sm: "150px",
                                    md: "200px",
                                  }}
                                  borderColor="transparent"
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </Box>
            </Flex>
          </Card>
        </Flex>
      </Flex>
      <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>DANH SÁCH THÍ SINH (NHÓM {groupId})</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ModalViewUser id={groupId} />
          </ModalBody>
          <ModalFooter>
            <Button
              borderRadius={8}
              colorScheme="blue"
              mr={3}
              onClick={onClose}
            >
              ĐÓNG
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Confirm
        visible={visibleConfirm}
        description={`Bạn có chắc chắn xóa nhóm ${groupId} không?`}
        onCloseConfirm={() => {
          setVisibleConfirm(false);
        }}
        buttonColor="red"
        isCentered={false}
        onConfirm={handleRemoveGroup}
      />
      <Confirm
        description={`Bạn có chắc chắn kết thúc thi nhóm ${groupId} không?`}
        visible={visibleConfirmEndTest}
        onCloseConfirm={() => {
          setVisibleConfirmEndTest(false);
        }}
        isCentered={false}
        buttonColor="red"
        onConfirm={handleEndTest}
      />
    </div>
  );
};

export default IndexInformationGroup;
