import {
  Avatar,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useAuthStore } from 'components/zustand/useAuthStore';
import { query, uploadFile } from 'helper/QueryHelper';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdUpload } from 'react-icons/md';

export default function ImageUploadModal({ isOpen, onClose }) {
  const brandColor = useColorModeValue('brand.500', 'white');
  const toast = useToast()
  const {loggedInUser, updateAvatar} = useAuthStore(state=>state)
  const [disableButton, setDisableButton] = useState(false)
  const [prevImage, setPrevImage] = useState(null)
  

  // react-dropzone
    const { acceptedFiles, fileRejections, getRootProps, getInputProps,  } =
    useDropzone({
      accept: {
        'image/*': ['.jpg', '.png'],
      },
      maxSize : 1000000, // <= 1mb
      maxFiles: 1
    });
    

  const sendImage = async()=>{
    const DATA = new FormData();
    DATA.append('files', acceptedFiles[0]);
    DATA.append('moduleName', 'SACHSO');
    DATA.append('subModuleName', 'RESOURCES');
    DATA.append('entityName', 'Questions');
    DATA.append('entityId', '46d30c13-3555-4395-8b83-4b9b2f7402ae');
    DATA.append('tags', 'QUIZCITY-WORKSHEET');

    // upload ảnh lên server chứa ảnh
      const response = await uploadFile(DATA)
      if(response ){
        const data = {
          Id: loggedInUser.id,
          Avatar: response.downloadUrl
        }
        updateAvatar(response.downloadUrl)
        
        // upload link ảnh từ server chứa ảnh lên sql

        const uploadImageToSQL = await query("p_System_User_InsertUpdate_Avatar", data)

        if(uploadImageToSQL && uploadImageToSQL[0].userId > 0){
          toast({
          title: 'Up ảnh thành công!',
          description: '',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
          containerStyle: {
            mt: '40px',
          },
        });

        acceptedFiles.length = 0
        const timer = acceptedFiles.length
        setDisableButton(false)
        setPrevImage(null)
        if(timer > 0){
          clearTimeout(timer)
        }
        setTimeout(() => {
          onClose()
        }, 2000);
        }
      }
  }
  const checkUpload = ()=>{
    if(fileRejections.length > 0){

       setDisableButton(true)
      acceptedFiles.length = 0
      const timer = fileRejections.length | 0

      toast({
        title: 'Thêm ảnh thất bại!',
        description: 'Định dạng ảnh jpg hoặc png. Kích thước ảnh < 1mb. Vui lòng không thêm nhiều ảnh 1 lần',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
        containerStyle: {
          mt: '40px',
        },
      });
      if(timer > 0){
        clearTimeout(timer)
      }
      setTimeout(()=>{
        setDisableButton(false)
      },9000)
      return
    }
  
  }
  
  const handleUpload = async () => {
    setDisableButton(true)

    if(acceptedFiles.length > 0){
      sendImage()
    } else{

      setDisableButton(true)
      const timer = acceptedFiles.length

      if(timer > 0){
        clearTimeout(timer)
      }
      setTimeout(()=>{
        setDisableButton(false)

      },3000)

      toast({
        title: 'Vui lòng chọn ảnh !',
        description: '',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
        containerStyle: {
          mt: '40px',
        },
      });
    }
  
  };
  const closeModal = ()=>{
    acceptedFiles.length = 0
    setPrevImage(null)
    onClose()
  }

  // check ảnh upload
  useEffect(()=>{
    if(acceptedFiles.length || fileRejections.length){
      checkUpload()
    }
    if(acceptedFiles.length > 0 && !fileRejections.length){
      setPrevImage(Object.assign(acceptedFiles[0],{
        preview : URL.createObjectURL(acceptedFiles[0])
      }))
    }
  },[acceptedFiles,fileRejections])
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={'lg'}>
      <ModalOverlay />
      <ModalContent >
        <ModalHeader>Cập nhật ảnh đại diện</ModalHeader>
        <ModalCloseButton onClick={()=>closeModal()}/>
        <ModalBody>
          <Flex
            align="center"
            justify="center"
            w="100%"
            h="max-content"
            minH="300px"
            cursor="pointer"
            borderWidth={2}
            borderRadius={2}
            borderColor="#eeeeee"
            borderStyle="dashed"
            backgroundColor="#fafafa"
            color="#bdbdbd"
            {...getRootProps({ className: 'dropzone' })}
          >
            <input {...getInputProps()} />
            {/* <Button variant="no-effects">{content}</Button> */}

            <Flex direction={'column'} justify={'center'} align={'center'}>
              <Icon as={MdUpload} w="60px" h="60px" color={brandColor} />
              <Text fontSize="md" fontWeight="700" color={brandColor}>
                Thêm Ảnh
              </Text>
              <Text fontSize="md" fontWeight="500" color="secondaryGray.500">
                Hỗ trợ định dạng jpg, png. Kích thước ảnh dưới 1mb
              </Text>
              <Text fontSize="md" fontWeight="500" color="secondaryGray.500">
                Khuyến nghị ảnh 400x400px
              </Text>
            </Flex>
          </Flex>
         
        </ModalBody>
        <Flex w={"100%"} justifyContent={"center"} >
          {/* {prevImage !== null ? <img src={prevImage.preview} width="300px"  alt='avatar' style={{marginTop:"10px" }}/>: <React.Fragment/>} */}
          {prevImage !== null ? <Avatar src={prevImage.preview} width="240px" height="240px" alt='avatar' style={{marginTop:"10px" }}/>: <React.Fragment/>}
        </Flex>

        <Flex align="center" justify="center" mt={"20px"} mb={'20px'}>
          <Button
            colorScheme="blue"
            borderRadius={'5px'}
            mr={3}
            onClick={() => handleUpload()}
            isDisabled ={disableButton}
          >
            Lưu
          </Button>
          <Button
            onClick={()=>{
             closeModal()
            }}
            bg="gray.500"
            color={'white'}
            borderRadius={'5px'}
          >
            Hủy
          </Button>
        </Flex>
      </ModalContent>
      <ModalFooter>
        
      </ModalFooter>
    </Modal>
  );
}
