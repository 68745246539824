import * as actionTypes from "../actions/types";
import _ from "lodash";

// ------------------------------------------------------------------------------------------------
const minute = 1000 * 60;
const initialState = {
  loading: false,
  error: null,
  examination: null,
  submittedQuestions: [],
  countdown: 30 * minute, // 30 minutes
};

// ------------------------------------------------------------------------------------------------

const OlympicQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OLYMPIC_START:
      return { ...state, submittedQuestions: [] };
    // --------------------------------------------------------------------------------------------
    case actionTypes.OLYMPIC_UPDATE_COUNTDOWN:
      return { ...state, countdown: action.payload };
    // --------------------------------------------------------------------------------------------
    case actionTypes.OLYMPIC_SUBMIT:
      var found = state.submittedQuestions.find(
        (item) => item.question.id === action.payload.question.id
      );

      if (found === undefined) {
        state.submittedQuestions = [
          ...state.submittedQuestions,
          action.payload,
        ];
      } else {
        found.userData = action.payload.userData;
      }

      return {
        ...state,
        submittedQuestions: [...state.submittedQuestions],
      };

    // case actionTypes.OLYMPIC_SUBMIT_SUCCESS:
    //   var found = state.submittedQuestions.find((item) => item.question.id === action.payload.question.id);

    //   if (found === undefined) {
    //     state.submittedQuestions = [...state.submittedQuestions, action.payload];
    //   } else {
    //     found.userData = action.payload.userData;
    //   }
    //   return {
    //     ...state,
    //     submittedQuestions: [...state.submittedQuestions],
    //   };
    // --------------------------------------------------------------------------------------------
    default:
      return state;
  }
};

export default OlympicQuizReducer;
