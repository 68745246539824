import React, { useEffect, useState } from 'react';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  Select,
  useToast,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
// Assets

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Apiquery from 'helper/QueryHelper';
import { useHistory } from 'react-router-dom';
import { MODULE_ID } from 'constants/settings';

export default function UserRegister() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  // const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  // variables
  // const [showPassword, setShowPassword] = React.useState(false);
  const [cityList, setCityList] = React.useState([]);
  const [cityId, setCityId] = React.useState(null);
  const [districtList, setDistrictList] = React.useState([]);
  const [successRegister, setSuccessRegister] = React.useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const history = useHistory();
  const toast = useToast();

  // yup
  const Schema = yup.object().shape({
    fullName: yup
      .string()
      .required('Vui lòng nhập đầy đủ họ và tên')
      .matches(
        /^[a-zA-Z]*[^0-9,.<>/?;:'"!@#$%^&*()]*$/,
        'Họ và tên phải là chữ. Không chứa số'
      ),

    phone: yup
      .string()
      .required('Vui lòng nhập số điện thoại')
      .matches(
        /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
        'Số điện thoại không hợp lệ'
      ),
    email: yup
      .string()
      .required('Vui lòng nhập email')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Vui lòng nhập đúng định dạng email'
      ),

    city: yup.string().required('Vui lòng chọn thành phố'),
    district: yup.string().required('Vui lòng chọn quận huyện'),
    sex: yup.string().required('Vui lòng chọn giới tính'),
    // password: yup
    //   .string()
    //   .min(6, 'Mật khẩu ít nhất 6 ký tự')
    //   .required('Vui lòng nhập mật khẩu'),
    // confirmPassword: yup
    //   .string()
    //   .oneOf([yup.ref('password'), null], 'Nhập lại mật khẩu không trùng khớp')
    //   .required('Vui lòng nhập mật khẩu'),
  });
  // hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });
  // Toast
  const warningToast = () => {
    toast({
      title: 'Email đăng ký đã tồn tại !',
      description: '',
      status: 'warning',
      duration: 2000,
      isClosable: true,
      position: 'top',
      containerStyle: {
        mt: '40px',
      },
    });
  };
  const successToast = () => {
    toast({
      title: 'Đăng ký tài khoản thành công !',
      description: 'Vui lòng truy cập Email để nhận mã kích hoạt',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top',
      containerStyle: {
        mt: '40px',
      },
    });
  };
  // get city list
  const getCityList = async () => {
    const res = await Apiquery.queryNotModule('p_METADATA_Cities_Get');
    setCityList(res);
  };

  // get district list
  const getDistrictList = async () => {
    if (cityId !== null) {
      const res = await Apiquery.queryNotModule(
        'p_METADATA_Districts_GetByCity',
        { cityId }
      );
      setDistrictList(res);
    }
  };

  useEffect(() => {
    getCityList();
    getDistrictList();
  }, [cityId]);

  // Đăng ký tài khoản thành công sau 3s chuyển hướng

  useEffect(() => {
    if (successRegister > 0) {
      const timer = setTimeout(() => {
        history.push('/verification', {
          userEmail: userEmail,
        });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successRegister]);

  // send form
  const onsubmit = async (values: any) => {
    const data = {
      Id: 0,
      FullName: values.fullName,
      Email: values.email.toLowerCase(),
      Phone: values.phone,

      CityId: values.city,
      DistrictId: values.district,

      Sex: values.sex === 'true' ? true : false,
      UserName: '',
      SchoolId: 0,
      Note: '',

      CreatedBy: '',
      SchoolTypeId: 0,
      Grade: 0,
      AreaId: 0,
    };
    if (values) {
      const sendData = await Apiquery.query('p_System_User_InsertUpdate', data);
      if (sendData[0]?.userId === 0) {
        return warningToast();
      } else {
        const dataSendMail = {
          To: values.email.toLowerCase(),
          IsSendFast: true,
          moduleId: MODULE_ID,
          Cc: '',
          Bcc: '',
        };
        const response = await Apiquery.amesSendMail(
          'SendMailFastOLPKids',
          dataSendMail
        );
        if (response.UserId > 0) {
          setDisableButton(true);
          setUserEmail(values.email.toLowerCase());
          setSuccessRegister(response?.UserId);
          successToast();
        }

        //=====test api Vĩ=========
      }
    }
  };
  return (
    <>
      <CenteredAuth
        image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
        cardTop={{ base: '140px', md: '14vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
        mx="0px"
      >
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          justifyContent="center"
          px={{ base: '20px', md: '0px' }}
          flexDirection="column"
          mb="30px"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="20px">
              Đăng ký tài khoản
            </Heading>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            {/* =====form=============== */}

            <form onSubmit={handleSubmit(onsubmit)}>
              <FormControl>
                {/* =======Full Name=============== */}
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  htmlFor="fullname"
                >
                  Họ và Tên<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  id="fullname"
                  {...register('fullName')}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="text"
                  placeholder="Nhập họ và tên"
                  fontWeight="500"
                  size="lg"
                />
                <Text fontSize={'xs'} mb="24px" mt="5px" color={'red'} ml="5px">
                  {errors?.fullName?.message}
                </Text>

                {/* =======email=============== */}
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  htmlFor="email"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  id="email"
                  {...register('email')}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="text"
                  placeholder="Nhập email"
                  fontWeight="500"
                  size="lg"
                />
                <Text fontSize={'xs'} mb="24px" mt="5px" color={'red'} ml="5px">
                  {errors?.email?.message}
                </Text>

                {/* =======Phone=============== */}
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  htmlFor="phone"
                >
                  Số điện thoại<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  id="phone"
                  {...register('phone')}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="text"
                  placeholder="Nhập số điện thoại"
                  fontWeight="500"
                  size="lg"
                />
                <Text fontSize={'xs'} mb="24px" mt="5px" color={'red'} ml="5px">
                  {errors?.phone?.message}
                </Text>
                {/* =======Sex (Gioi tinh)=============== */}
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  htmlFor="phone"
                >
                  Giới Tính<Text color={brandStars}>*</Text>
                </FormLabel>
                <RadioGroup>
                  <Stack spacing={5} direction="row">
                    <Radio value="true" {...register('sex')}>
                      Nam
                    </Radio>
                    <Radio value="false" {...register('sex')}>
                      Nữ
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Text fontSize={'xs'} mb="24px" mt="5px" color={'red'} ml="5px">
                  {errors?.sex?.message}
                </Text>

                {/* ======City=============== */}
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  htmlFor="city"
                >
                  Chọn thành phố<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  ms={{ base: '0px', md: '4px' }}
                  size="lg"
                  variant="auth"
                  fontSize="sm"
                  {...register('city')}
                  id="city"
                  onChange={(e: any) => setCityId(e.target.value)}
                >
                  <option hidden value="">
                    Chọn Thành Phố
                  </option>
                  {cityList && cityList.length > 0 ? (
                    cityList.map((city) => {
                      return (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      );
                    })
                  ) : (
                    <React.Fragment />
                  )}
                </Select>
                {cityId === null ? (
                  <Text
                    fontSize={'xs'}
                    mb="24px"
                    mt="5px"
                    color={'red'}
                    ml="5px"
                  >
                    {errors?.city?.message}
                  </Text>
                ) : (
                  <Text
                    fontSize={'xs'}
                    mb="24px"
                    mt="5px"
                    color={'red'}
                    ml="5px"
                  ></Text>
                )}

                {/* ======district=============== */}
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  htmlFor="district"
                >
                  Chọn quận huyện<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  id="district"
                  ms={{ base: '0px', md: '4px' }}
                  size="lg"
                  variant="auth"
                  fontSize="sm"
                  {...register('district')}
                >
                  <option hidden value="">
                    Chọn quận huyện
                  </option>
                  {cityId === null ? (
                    <option value="" disabled style={{ fontSize: '16px' }}>
                      Bạn chưa chọn thành phố
                    </option>
                  ) : (
                    ''
                  )}
                  {districtList && districtList.length > 0 ? (
                    districtList.map((district) => {
                      return (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      );
                    })
                  ) : (
                    <React.Fragment />
                  )}
                </Select>
                <Text fontSize={'xs'} mb="10px" mt="5px" color={'red'} ml="5px">
                  {errors?.district?.message}
                </Text>

                <Button
                  ms={{ base: '0px', md: '4px' }}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  // mb="24px"
                  mt="24px"
                  type="submit"
                  isDisabled={disableButton}
                  _hover={{ bg: brandStars }}
                >
                  Đăng ký
                </Button>
              </FormControl>
            </form>
          </Flex>
        </Flex>
      </CenteredAuth>
    </>
  );
}
