/*eslint-disable*/

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdOutlineLocationOn, MdOutlinePhoneInTalk } from 'react-icons/md';

export default function KidsFooter() {
  const textColor = useColorModeValue('gray.400', 'white');
  const brandStars = useColorModeValue('navy.50', 'navy.50');

  return (
    <Flex
      w={{ base: '100%', xl: '1170px' }}
      maxW={{ base: '90%', xl: '1170px' }}
      zIndex="1.5"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="center"
      px={{ base: '0px', xl: '0px' }}
      pb="30px"
      mx="auto"
    >
      <Grid templateColumns="repeat(12,1fr)" columnGap={4} w={'100%'}>
        <GridItem
          me={{
            base: '20px',
            md: '44px',
          }}
          ms={{ base: '20px' }}
          mb={'20px'}
          colSpan={{ sm: 12, md: 6, lg: 6 }}
        >
          <Text fontWeight="500" color={textColor} mb={'10px'}>
            THƯỜNG TRỰC BAN TỔ CHỨC
          </Text>
          <Text fontWeight="500" color={textColor} mb={'10px'}>
            Tên đơn vị : Đang cập nhật
            <br />
          </Text>
          {/* ===========dia chi lien he======================== */}

          <Flex
            fontWeight="500"
            mb="5px"
            color={textColor}
            align={'center'}
            gap={1}
          >
            <MdOutlineLocationOn />
            <Text fontSize={'xs'}>Đang cập nhật</Text>
          </Flex>
          {/* ===========dien thoai========================= */}

          <Flex fontWeight="500" color={textColor} align={'center'} gap={1}>
            <MdOutlinePhoneInTalk />
            <Text fontSize={'xs'}>Điện thoại:</Text>
            <Text fontSize={'xs'} color="#728fea">
              <a target="_blank">Đang cập nhật</a>
            </Text>
          </Flex>
        </GridItem>

        <GridItem
          me={{
            base: '20px',
            md: '44px',
          }}
          ms={{ base: '20px' }}
          mb={'20px'}
          colSpan={{ sm: 12, md: 6, lg: 6 }}
        >
          <Text fontWeight="500" color={textColor} mb={'10px'}>
            ĐƠN VỊ ĐỒNG HÀNH
          </Text>
          <Text fontWeight="500" color="#728fea" mb={'10px'}>
            <a href="https://ames.edu.vn/" target="_blank">
              HỆ THỐNG ANH NGỮ QUỐC TẾ AMES
            </a>
            <br />
          </Text>
          {/* ===========dia chi lien he======================== */}
          <Flex
            fontWeight="500"
            mb="5px"
            color={textColor}
            align={'center'}
            gap={1}
          >
            <MdOutlineLocationOn />
            <Text fontSize={'xs'} color="#728fea">
              <a href="https://ames.edu.vn/lien-he" target="_blank">
                Địa chỉ liên hệ
              </a>
            </Text>
          </Flex>
          {/* ===========dien thoai========================= */}
          <Flex fontWeight="500" color={textColor} align={'center'} gap={1}>
            <MdOutlinePhoneInTalk />
            <Text fontSize={'xs'}>Tổng đài hỗ trợ kỹ thuật:</Text>
            <Text fontSize={'xs'} color="#728fea">
              <a href="tel:18002098" target="_blank">
                1800 2098
              </a>
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
}
