import React from 'react';
// Chakra imports
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';

// Assets
import AdminAccountSetting from './AdminAccountSetting';
import ChangePassword from 'views/user/profile/ChangePassword';
import UserProfile from 'views/user/profile/UserProfile';

export default function IndexAdminProfile() {
  return (
    <Flex
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      justifyContent={'center'}
    >
      <Flex w={'90%'}>
        <Grid
          mb="20px"
          templateColumns="repeat(12,1fr)"
          w={'100%'}
          // columns={{ sm: 1, lg: 2 }}
          // spacing={{ base: '20px', xl: '20px' }}
        >
          {/* Column Left */}
          <GridItem colSpan={12}>
            <UserProfile />
          </GridItem>
          <GridItem colSpan={{ lg: 6, sm: 12 }} pr={{ md: '0', lg: '20px' }}>
            <AdminAccountSetting />
          </GridItem>
          <GridItem colSpan={{ lg: 6, sm: 12 }} mt={{ sm: '20px', lg: '0' }}>
            <ChangePassword />
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
}
