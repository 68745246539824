import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Input,
  Menu,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import * as QueryHelper from "helper/QueryHelper";
import _ from "lodash";
import {
  AddDataCollection,
  AddDataDocument,
  DeleteCollection,
  DeleteDocument,
  DocCollection,
  UpdateCollection,
} from "modules/firebase/docDocument";
import Confirm from "modules/confirm";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import { async } from "@firebase/util";
import {
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "configs/firebase";
import {
  Paginator,
  Container,
  Previous,
  Next,
  PageGroup,
  usePaginator,
} from "chakra-paginator";
import { FcLeftDown, FcUpLeft } from "react-icons/fc";
type RowObj = {
  email: string;
  timeLogin: string;
  status: number;
  idFirebase: string;
  edit: any;
  data: any;
};

const columnHelper = createColumnHelper<RowObj>();

const IndexDivideIntoGroups = () => {
  const [dataTable, setDataTable] = React.useState<RowObj[]>([]);

  const [isSpinning, setSpinning] = React.useState<boolean>(false);
  const [listStudent, setListStudent] = React.useState<any>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visibleConfirmRemoveUser, setVisibleConfirmRemove] =
    React.useState<boolean>(false);
  const [visibleConfirmRemoveUserById, setVisibleConfirmRemoveById] =
    React.useState<boolean>(false);
  const [dataUser, setDataUser] = React.useState<any>();
  const [visibleConfirmDivideGr, setVisibleConfirmDivideGr] =
    React.useState<boolean>(false);
  const [totalStudentInGroup, setTotalStudentInGroup] =
    React.useState<number>(1);
  const [countUserNotReady, setCountNotReady] = React.useState<number>(0);
  const toastMessage = useToast();
  const [numberSliceTable, setNumberSliceTable] = React.useState<number>(10);
  const [numberListPage, setNumberListPage] = React.useState<number>(10);
  const {
    data: dataUserLogin,
    isLoading: loginUserLogin,
    error,
  }: any = DocCollection({
    nameCollection: "login",
  });
  const pagesQuantity = 12;
  const { currentPage, setCurrentPage } = usePaginator({
    initialState: { currentPage: 1 },
  });
  React.useEffect(() => {
    dataUserLogin
      ?.docChanges({ includeMetadataChanges: true })
      .forEach((change: any) => {
        if (change.type === "added" || change.type === "modified") {
          const { data, timeLogin, status } = change.doc.data();
          _.remove(dataTable, {
            email: data?.email,
          });
          setDataTable((prev: any) => [
            ...prev,
            {
              email: data?.email,
              data,
              timeLogin,
              status,
              idFirebase: change.doc.id,
            },
          ]);
          return;
        } else if (change.type === "removed") {
          _.remove(dataTable, {
            email: change.doc.data()?.data.email,
          });
          setDataTable([...dataTable]);
        }
      });
  }, [dataUserLogin]);

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const statusStudent = (status: number) => {
    switch (status) {
      case 0:
        return <Badge colorScheme="red">CHƯA SẴN SÀNG</Badge>;
      case 1:
        return <Badge colorScheme="green">ĐÃ SẴN SÀNG</Badge>;
      // case 2:
      //   return <Badge colorScheme='red'>ĐÃ THOÁT</Badge>
      // case 3:
      //   return <Badge colorScheme='red'>CHUYỂN TAB / ĐÓNG TAB</Badge>
      // case 4:
      //   return <Badge colorScheme='yellow'>QUAY LẠI TRANG</Badge>
      case 5:
        return <Badge colorScheme="green">BẮT ĐẦU THI</Badge>;

      default:
        return;
    }
  };
  const columns = [
    columnHelper.accessor("data.fullName", {
      id: "data.fullName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Họ và tên
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Flex direction="column">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor("data.phone", {
      id: "data.phone",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Số điện thoại
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Flex direction="column">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Email
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Flex direction="column">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor("timeLogin", {
      id: "timeLogin",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Thời gian đang nhập
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Flex direction="column">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          TRẠNG THÁI
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Flex direction="column">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {statusStudent(info.getValue())}
            </Text>
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor("data", {
      id: "data",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          THAO TÁC
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Button
            onClick={() => {
              setDataUser(info.getValue());
              setVisibleConfirmRemoveById(true);
            }}
            color="red.500"
            variant="outline"
          >
            Xóa thí sinh
          </Button>
        </Flex>
      ),
    }),
  ];
  const table = useReactTable({
    data: dataTable,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const selectInformationStudent = async () => {
    const dataUser = await QueryHelper.query("p_System_User_Select");
    setListStudent(dataUser);
  };

  const addStudentIntoGroup = async (group: string | number) => {
    await QueryHelper.query("p_Examinations_ListStudentOfGroup_CheckIsPass ", {
      GroupId: group,
      ExamId: "7121A5FC-711F-4584-AB7C-AF3E3CCC8B29",
    }).then(async (res) => {
      let arr: any[] = [];
      res.map((item: any, index: number) => {
        arr.push({ ...item, isPass: true });
      });

      AddDataDocument({
        nameDocument: `Group`,
        idDocument: `Group-${group}`,
        nameCollection: "users",
        parameters: {
          data: arr,
          createdAt: serverTimestamp(),
        },
      });
    });
  };

  const accessDivideGroup = () => {
    if (totalStudentInGroup > 0) {
      QueryHelper.query("p_Examinations_Groups_TaoNhomThi", {
        grade: 0,
        RoundId: 0,
        TotalUserInGroup: totalStudentInGroup,
      }).then((dataGroup) => {
        if (dataGroup?.[0].data?.[0].message === "") {
          toastMessage({
            title: "Thông báo từ hệ thống",
            description: "Phân bổ nhóm thành công",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
            onCloseComplete() {
              onClose();
              setSpinning(false);
            },
          });
          dataGroup?.[0].data?.map((item: any) => {
            QueryHelper.query("p_Examinations_Groups_GetUser", {
              GroupId: item.groupId,
            }).then((listUserGroups) => {
              listUserGroups?.map((item: any) => {
                AddDataCollection({
                  nameCollection: "messageToStudent",
                  parameters: {
                    data: `Bạn đã được chia vào ${item.examGroupId}`,
                    toStudent: item.email,
                    groupId: item.examGroupId,
                    type: "divideGroup",
                  },
                })
                  .then(() => {
                    addStudentIntoGroup(item.examGroupId).then(() => {
                      onClose();
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            });
          });
        } else {
          toastMessage({
            title: "Thông báo từ hệ thống",
            description: "Phân bổ nhóm thất bại. Nhóm đã có trên hệ thống.",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
            onCloseComplete() {
              setSpinning(false);
              onClose();
            },
          });
        }
      });
      setVisibleConfirmDivideGr(false);
      onClose();
    } else {
      toastMessage({
        title: "Thông báo từ hệ thống",
        description: "Số lượng thí sinh trong 1 nhóm phải lớn hơn 0",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleDivideGroup = () => {
    setSpinning(true);
    const countNotReady = _.countBy(dataTable, (e: any) => e.status === 0);
    if (countNotReady?.true > 0) {
      setCountNotReady(countNotReady?.true);
      setVisibleConfirmDivideGr(true);
    } else {
      if (countNotReady?.false > 0) {
        accessDivideGroup();
      } else {
        toastMessage({
          title: "Thông báo hệ thống",
          description: "Không có thí sinh nào sẵn sàng",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  const handleRemoveListReady = async () => {
    //Xoas
    dataTable?.forEach((e) => {
      AddDataCollection({
        nameCollection: "messageToStudent",
        parameters: {
          data: `Tài khoản của bạn đã bị xóa khỏi hệ thống`,
          toStudent: e.email,
          type: "remove",
        },
      });
    });

    QueryHelper.query("p_Examinations_Student_Ready_Delete");
    DeleteCollection({ nameCollection: "login" });

    DeleteCollection({ nameCollection: "messageToStudent" });

    setDataTable([]);
  };
  const handleRemoveUserById = async () => {
    QueryHelper.query("p_Examinations_Student_Ready_Delete_byId", {
      UserId: dataUser.id,
    }).then(() => {
      const queryUserFirebase = query(
        collection(db, "login"),
        where("data.id", "==", dataUser.id)
      );
      getDocs(queryUserFirebase).then((res) => {
        res.docs?.forEach((e) => {
          DeleteDocument({
            nameDocument: "login",
            idDocument: e.id,
          });
        });
      });
      const queryMessageToStudentFirebase = query(
        collection(db, "messageToStudent"),
        where("toStudent", "==", dataUser.email)
      );
      getDocs(queryMessageToStudentFirebase).then((res) => {
        res.docs?.forEach((e) => {
          DeleteDocument({
            nameDocument: "messageToStudent",
            idDocument: e.id,
          });
        });
      });
      _.remove(dataTable, {
        email: dataUser.email,
      });
      setDataTable([...dataTable]);
      setVisibleConfirmRemoveById(false);
    });
  };

  const handleReady = () => {
    QueryHelper.query("p_Examinations_Student_Ready_Delete").then(() => {
      const unreadyUserRef = query(
        collection(db, "login"),
        where("status", "!=", 5)
      );
      getDocs(unreadyUserRef).then((res) => {
        res.docs?.forEach((doc) => {
          QueryHelper.query("p_Examinations_Student_Ready", {
            userId: doc.data().data.id,
          }).then(() => {
            UpdateCollection({
              nameCollection: "login",
              id: doc.id,
              parameters: {
                status: 1,
              },
            });
          });
        });
      });
    });
  };

  React.useEffect(() => {
    selectInformationStudent();
  }, []);
  const handleCurrentPage = (page: any) => {
    setNumberSliceTable((prev: number) =>
      page === 1 ? numberListPage : prev * page
    );
    setCurrentPage(page);
  };
  const handlePageQuantityChange = (values: any) => {
    setNumberListPage(values.target.value);
    setNumberSliceTable(values.target.value);
  };
  return (
    <Flex pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex direction="column" width="stretch">
        <Card padding={"20px 0"}>
          <Flex
            direction="column"
            w="100%"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            <Flex
              align={{ lg: "center" }}
              justify={{ base: "space-between" }}
              w="100%"
              px="20px"
              mb="20px"
            >
              <Flex direction="column">
                <Text
                  color={textColor}
                  fontSize="xl"
                  fontWeight="600"
                  lineHeight="100%"
                >
                  DANH SÁCH THÍ SINH ĐÃ ĐĂNG NHẬP
                </Text>
                <br />
                <Text
                  color={textColor}
                  fontSize="md"
                  fontWeight="500"
                  lineHeight="100%"
                >
                  {/* Số lượng: student1@gmail.com */}
                </Text>
              </Flex>
              <Flex gap={5}>
                <Button
                  onClick={() => {
                    setVisibleConfirmRemove(true);
                  }}
                  color="red.500"
                  variant="outline"
                >
                  Xóa danh sách thí sinh
                </Button>
                <Button onClick={handleReady} colorScheme="green">
                  Sẵn sàng
                </Button>
                <Button colorScheme="green" onClick={onOpen}>
                  Phân bổ nhóm
                </Button>
              </Flex>
            </Flex>
            <Box>
              <Table variant="simple" color="gray.500" mt="12px">
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <Th
                            key={header.id}
                            colSpan={header.colSpan}
                            pe="10px"
                            borderColor={borderColor}
                            cursor="pointer"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <Flex
                              justifyContent="space-between"
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: "",
                                desc: "",
                              }[header.column.getIsSorted() as string] ?? null}
                            </Flex>
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table
                    .getRowModel()
                    .rows.slice(
                      numberSliceTable - numberListPage,
                      numberSliceTable
                    )
                    .map((row) => {
                      return (
                        <Tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Td
                                key={cell.id}
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor="transparent"
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </Box>
            <Paginator
              pagesQuantity={Math.ceil(dataTable?.length / 10)}
              currentPage={currentPage}
              onPageChange={handleCurrentPage}
              activeStyles={{
                borderRadius: 5,
                height: "auto",
                padding: "5px",
                backgroundColor: "#11047a",
                color: "#ffffff",
              }}
              normalStyles={{
                borderRadius: 5,
                height: "auto",
                padding: "5px",
              }}
            >
              <Container align="center" justify="center" w="full" p={4}>
                <Previous
                  style={{
                    display: "inline",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  {/* <Arrpw /> */}
                  <ChevronLeftIcon />
                </Previous>
                <PageGroup isInline align="center" />

                <Next
                  style={{
                    display: "inline",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <ChevronRightIcon />
                </Next>
                <Select w={40} ml={5} onChange={handlePageQuantityChange}>
                  <option value={10}>10 / Trang</option>
                  <option value={25}>25 / Trang</option>
                  <option value={50}>50 / Trang</option>
                  <option value={75}>75 / Trang</option>
                  <option value={100}>100 / Trang</option>
                </Select>
              </Container>
            </Paginator>
            {visibleConfirmDivideGr ? (
              <Confirm
                description={`Có ${countUserNotReady} thí sinh chưa sẵn sàng.`}
                visible={visibleConfirmDivideGr}
                onCloseConfirm={() => {
                  setVisibleConfirmDivideGr(false);
                  onClose();
                }}
                icon={<WarningTwoIcon color={"orange.400"} />}
                isCentered={false}
                buttonColor="blue"
                onConfirm={() => {
                  accessDivideGroup();
                }}
                okText="Tiếp tục"
                cancelText="Đóng"
              />
            ) : (
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Phân bổ nhóm</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <NumberInput
                      onChange={(valueString) =>
                        setTotalStudentInGroup(Number(valueString))
                      }
                      step={1}
                      defaultValue={totalStudentInGroup}
                      min={0}
                      max={100}
                    >
                      <NumberInputField placeholder="Nhập số thí sinh trong 1 nhóm" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                      Đóng
                    </Button>
                    <Button
                      disabled={isSpinning}
                      colorScheme="blue"
                      onClick={handleDivideGroup}
                    >
                      Đồng ý
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
            <Confirm
              description="Bạn có chắc chắn muốn xóa toàn bộ danh sách thi không?"
              visible={visibleConfirmRemoveUser}
              onCloseConfirm={() => {
                setVisibleConfirmRemove(false);
              }}
              buttonColor="red"
              onConfirm={() => {
                handleRemoveListReady();
                setVisibleConfirmRemove(false);
              }}
              isCentered={false}
              okText="Đồng ý"
              cancelText="Đóng"
            />
            <Confirm
              description={`Bạn có chắc chắn muốn xóa thí sinh ${dataUser?.fullName} không ? `}
              visible={visibleConfirmRemoveUserById}
              onCloseConfirm={() => {
                setVisibleConfirmRemoveById(false);
              }}
              buttonColor="red"
              onConfirm={() => {
                handleRemoveUserById();
              }}
              isCentered={false}
              okText="Đồng ý"
              cancelText="Đóng"
            />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default IndexDivideIntoGroups;
