// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  PinInput,
  PinInputField,
  Heading,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { MODULE_ID } from 'constants/settings';

import { amesSendMail, query} from 'helper/QueryHelper';
import CenteredAuth from 'layouts/auth/variants/Centered';
import React,{useState,useEffect} from 'react'
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup'

function VerificationForm() {
  // Chakra color mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  // variable
  const history = useHistory()
  const location = useLocation()
  const userEmail = location.state?.userEmail || null
  const [activeStatus, setActiveStatus] = React.useState(0)
  const [activeSuccess, setActiveSuccess] = React.useState(0)

  const [message,setMessage] = useState(null)
  const [disableButton,setDisableButton] = useState(false)
  const [formValue, setFormValue] = useState('')

  const toast = useToast()
  
 //yup
 const Schema = yup.object().shape({
  value1: yup.string().required(),
  value2: yup.string().required(),
  value3: yup.string().required(),
  value4: yup.string().required(),
  value5: yup.string().required(),
  value6: yup.string().required()

});
  // hook form
const {
  register,
  handleSubmit,
  reset,
  formState:{errors}
} = useForm({
  resolver: yupResolver(Schema)
});


//validate status
  const validateStatus =()=>{
   if(message !== null){
    if(activeStatus > 0){
      toast({
        title: message,
        description:"",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
        containerStyle: {
          mt: "40px",
        },
      });
      
      setMessage(null)
      setDisableButton(true)
      return
    }
    else{
      toast({
        title: message,
        description:"",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
        containerStyle: {
          mt: "40px",
        },
      });
      setMessage(null)
      setDisableButton(true)

      return
    }
  }
  }

//Kích hoạt tài khoản thành công sau 3s chuyển sang login
useEffect(()=>{
  validateStatus()
  if(activeSuccess > 0){
    setTimeout(()=>{
      history.push('/login')

    },2000)
  }
},[message, activeSuccess])

//disable button mã kích hoạt
useEffect(()=>{
  if(disableButton){
    const timer = setTimeout(()=>{
      setDisableButton(false)

    },2000)
    return ()=> clearTimeout(timer)
  }
},[disableButton])

// người dùng tự gõ vào url /verification => về login
useEffect(()=>{
  if(!userEmail){
    history.push("/login")
  }
},[])

// send form
  const onSubmit = async() => {
    const code = formValue
    validateAccount(code)
    // setFormValue('')
     reset()

  }

  const handleChangeForm =(value)=>{
    setFormValue(value)
  } 
  
  
 
  // click button xác nhận => validate tài khoản
  const validateAccount = async(code) => {
    const data ={
      Email : userEmail ,
      VerifyCode :code
    }
     const response = await query("p_System_User_VerifyEmail",data)

     if(response && response.length > 0){
      setActiveStatus(response[0]?.userId)
      setMessage(response[0]?.message)
      setActiveSuccess(response[0]?.userId)

    } 
     return
   }

   // click button gửi lại mã kích hoạt
   const resendCode = async()=>{
     const data = {
      To: userEmail,
      // To: "duyvv1@ames.edu.vn",
      IsSendFast : true, 
      moduleId: MODULE_ID ,
      "Cc" : "", 
      "Bcc" : "" 
      }
      // api Vĩ
    const response = await amesSendMail ('SendMailFastOLPKids', data)
      if(response?.UserId > 0){
       setActiveStatus(response?.UserId)
       setMessage(response?.Message)
        return
     } 
     else {
      setActiveStatus(response?.UserId)
      setMessage(response?.Message)
     }
   }

  return (
    <CenteredAuth
      image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
      cardTop={{ base: '140px', md: '24vh' }}
      cardBottom={{ base: '50px', lg: 'auto' }}
    >
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        justifyContent="center"
        px={{ base: '25px', md: '0px' }}
        flexDirection="column"
      >

        <Box me="auto" mb="34px">
          <Heading
            color={textColor}
            fontSize="36px"
            mb="16px"
            mx={{ base: 'auto', lg: 'unset' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
             Nhập mã kích hoạt 
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '425px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={handleSubmit(onSubmit)} onPaste={(e)=>{e.preventDefault(); return false}}>
          <FormControl>
            <Flex justify="center">
              <PinInput mx="auto" otp onChange={handleChangeForm} value={formValue}>
                <PinInputField
                  fontSize="26px"
                  color={textColor}
                  borderRadius="6px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '45px' }}
                  w={{ base: '63px', md: '45px' }}
                  me="10px"
                  {...register("value1")}
                  
                />
                <PinInputField
                  fontSize="26px"
                  color={textColor}
                  borderRadius="6px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '45px' }}
                  w={{ base: '63px', md: '45px' }}
                  me="10px"
                  {...register("value2")}

                />
                <PinInputField
                  fontSize="26px"
                  color={textColor}
                  borderRadius="6px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '45px' }}
                  w={{ base: '63px', md: '45px' }}
                  me="10px"
                  {...register("value3")}

                />
                <PinInputField
                  fontSize="26px"
                  color={textColor}
                  borderRadius="6px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '45px' }}
                  w={{ base: '63px', md: '45px' }}
                  me="10px"
                  {...register("value4")}


                />
                <PinInputField
                  fontSize="26px"
                  color={textColor}
                  borderRadius="6px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '45px' }}
                  w={{ base: '63px', md: '45px' }}
                  me="10px"
                  {...register("value5")}

                />
                <PinInputField
                  fontSize="26px"
                  color={textColor}
                  borderRadius="6px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '45px' }}
                  w={{ base: '63px', md: '45px' }}
                  me="10px"
                  {...register("value6")}

                />
                
              </PinInput>
             
            </Flex>
            {errors?.value1 || errors?.value2 || errors?.value3 || errors?.value4 || errors?.value5 || errors?.value6 ? <Text textAlign="center" fontSize="sm" color="red" mt="15px">Vui lòng nhập đầy đủ mã kích hoạt</Text> :<React.Fragment/>  }
            <Button
              fontSize="14px"
              variant="brand"
              borderRadius="16px"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              mt="22px"
              type='submit'
              isDisabled={disableButton}
              _hover={{bg:brandStars}}
            >
              Xác nhận
            </Button>
            
          </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text
              color={textColorDetails}
              fontWeight="400"
              fontSize="14px"
              mx={{ base: 'auto', lg: 'unset' }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              Chưa nhận được mã kích hoạt?
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500" cursor={`${disableButton? "auto" : "pointer"}`} 
               
               onClick={()=>!disableButton? resendCode(): ""}

              >
                Gửi lại mã kích hoạt
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}


export default VerificationForm;
