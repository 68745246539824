import { WarningIcon, WarningTwoIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
} from '@chakra-ui/react'
import React from 'react'

type Props = {
  title?: string
  description?: string
  okText?: string
  cancelText?: string
  visible: boolean
  buttonColor: string
  onCloseConfirm: () => void | Function
  onConfirm: () => void | Function
  isCentered?: boolean
  icon?: JSX.Element | undefined
}

const Confirm = ({
  title = 'Cảnh báo',
  description = 'Bạn có chắc chắn muốn xóa không!',
  okText = 'Đồng ý',
  cancelText = 'Đóng',
  visible,
  buttonColor,
  onCloseConfirm,
  onConfirm,
  isCentered = true,
  icon = undefined,
}: Props) => {
  const cancelRef = React.useRef()
  return (
    <AlertDialog isCentered={isCentered} isOpen={visible} leastDestructiveRef={cancelRef} onClose={onCloseConfirm}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold' textTransform='uppercase'>
            <Flex alignItems='center' gap={2}>
              {icon}
              {title}
            </Flex>
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            <Button borderRadius={5} ref={cancelRef} onClick={onCloseConfirm}>
              {cancelText}
            </Button>
            <Button borderRadius={5} colorScheme={buttonColor} onClick={onConfirm} ml={3}>
              {okText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default Confirm
